<template>
    <div v-color="{ bg: page.color, light: page.lightColor }">
        <asset
            :asset="page.assetFull"
            :vimeoID="page.vimeoID"
            :cover="true"
            class="p-project_cover"
        />

        <header class="p-project_header | grid">
            <anim-text
                tag="h1"
                :text="page.title"
                :visible="true"
                class="t-h2"
            />
            <categories :categories="page.categories" />
        </header>

        <builder
            :blocks="page.builder"
            :radius="page.borderRadius"
        />

        <app-section>

            <nav
                v-if="nav.length > 0"
                class="o-project-nav | grid"
            >
                <anim-text
                    tag="h3"
                    text="More projects"
                    class="o-project-nav_title | t-h2"
                />
                <div class="o-project-nav_list">
                    <div
                        v-for="(item, i) in nav"
                        :key="`project-jav-${i}`"
                        class="o-project-nav_item"
                    >
                        <h2 class="o-project-nav_name | t-h2">
                            <icon
                                :icon="i === 0 ? 'arrow-left' : 'arrow-right'"
                                class="o-project-nav_arrow"
                            />
                            <router-link
                                :to="item.slug"
                                class="t-link"
                                v-cursor.click
                            >
                                {{ item.title }}
                            </router-link>
                            <sup> {{ i === 0 ? 'previous' : 'next' }}</sup>
                        </h2>
                        <p
                            v-if="item.headline"
                            class="o-project-nav_headline | t-h3 -lower"
                        >
                            {{ item.headline }}
                        </p>
                        <div class="o-project-nav_cover">
                            <asset
                                :asset="item.assetHalf"
                                :vimeoID="item.vimeoID"
                                :ratio="4/3"
                                :url="item.slug"
                            />
                        </div>
                    </div>
                </div>
            </nav>
        </app-section>
    </div>

</template>

<script>

import Builder from 'builder/Builder';
import AnimText from 'objects/AnimText';
import Asset from 'objects/Asset';
import Categories from 'objects/Categories';
import Icon from 'objects/Icon';
import AppSection from 'layout/AppSection';

export default {
    name: 'Project',
    components: {
        Builder,
        AnimText,
        Asset,
        Categories,
        Icon,
        AppSection,
    },
    props: {
        page: Object,
    },
    computed: {
        nav() {
            return [
                this.$store.getters['project/getPreviousElementById'](this.page.id),
                this.$store.getters['project/getNextElementById'](this.page.id),
            ]
        }
    }
};

</script>

<style lang="scss">

.p-project {}

.p-project_cover {
    height: vh(80);
}

.p-project_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: spacer(4);
    margin-bottom: spacer(3);

    .o-categories {
        // width: 100%;
    }

    @media #{md("xs", "max")} {
        flex-direction: column;

        .o-categories {
            margin-top: var(--unit);
        }
    }

    @media #{md("xs")} {

        .o-categories {
            // justify-content: flex-end;
            flex-shrink: 0;
            margin-left: var(--grid-gutter);
        }
    }
}


/*----------  Project Nav  ----------*/

.o-project-nav {
    margin-top: spacer(8);
}

.o-project-nav_title {
    padding-bottom: spacer(7);
}

.o-project-nav_list {
    display: grid;

    @media #{md("xs", "max")} {
        grid-row-gap: spacer(4);
    }

    @media #{md("xs")} {
        grid-column-gap: grid-space(1/12, 2);
        grid-template-columns: grid-space(5/12) grid-space(5/12);
    }
}

.o-project-nav_item {
    display: flex;
    flex-direction: column;
}

.o-project-nav_arrow,
.o-project-nav_name,
.o-project-nav_headline {
    margin-bottom: spacer(1);
}

.o-project-nav_arrow {
    display: block;
}

.o-project-nav_name {

    sup {
        margin-left: .5em;
        text-transform: uppercase;
    }
}

.o-project-nav_cover {
    display: block;
    margin-top: auto;

    @media #{md("md")} {
        width: grid-space(3/5);
    }
}


</style>
