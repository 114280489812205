<template>
    <div class="b-content | grid">
        <anim-text
            v-if="data.headline"
            tag="h3"
            :text="data.headline"
            class="b-content_headline | t-h4"
        />
        <div
            v-if="data.children.length > 0"
            class="b-content_grid"
        >
            <div
                v-for="(col, i) in data.children"
                :key="`col-${i}`"
                class="b-content_col"
                :class="`-w-${col.width} -o-${col.offset}`"
            >
                <anim-text
                    :cms="true"
                    :text="col.body"
                    class="t-body"
                />
            </div>
        </div>
    </div>
</template>

<script>

import AnimText from 'objects/AnimText';

export default {
    name: 'BlockContent',
    components: {
        AnimText
    },
    props: {
        data: Object,
    },
};

</script>

<style lang="scss">

.b-content {
    margin-top: spacer(4);
    margin-bottom: spacer(8);
}

.b-content_headline {
    margin-bottom: spacer(3);
}

.b-content_grid {
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gutter);
}

.b-content_col {
    width: var(--content-col-width, 100%);
    margin-left: var(--content-col-offset, 0%);

    &.-w-1\/4 {
        --content-col-width: #{grid-space(1/2)};
    }

    @media #{md("xs")} {

        &.-w-1\/2 {
            --content-col-width: #{grid-space(1/2)};
        }

        &.-w-1\/3 {
            --content-col-width: #{grid-space(1/3)};
        }

        &.-w-1\/4 {
            --content-col-width: #{grid-space(1/4)};
        }

        @for $i from 1 through 4 {
            &.-o-#{$i}\/6 {
                --content-col-offset: #{grid-space($i/6, 1)};
            }
        }
    }

    @media #{md("sm")} {

        &.-w-1\/4 {
            --content-col-width: #{grid-space(1/4)};
        }
    }
}


</style>
