<template>
    <div
        v-if="video"
        :class="className"
    >
        <video
            ref="video"
            :src="lazy ? '' : video.url"
            :poster="placeholder"
            loop="true"
            autoplay="true"
            muted="true"
            playsinline="true"
            preload="auto"
            @loaded="loaded()"
        ></video>
    </div>
</template>

<script>

import { ScrollTrigger } from 'src/gsap';

export default {
    name: 'AppVideo',
    props: {
        asset: {
            type: [Array, Object],
            required: true
        },
        lazy: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        isLoaded: false,
    }),
    created() {
        this.isLoaded = this.lazy
    },
    mounted() {
        const $video = this.$refs.video

        if(this.lazy) {
            $video.src = this.video.url
        }

        this.scrollTrigger = ScrollTrigger.create({
            trigger: $video,
            start: 'top bottom',
            end: 'bottom top',
            onToggle: ({ isActive }) => {
                if(isActive) {
                    $video.play()
                } else {
                    $video.pause()
                }
            }
        })
    },
    computed: {
        className() {
            let classname = 'o-video'

            if(this.lazy) {
                classname += ' -lazy'

                if(this.loaded) {
                    classname += ' is-loaded'
                }
            }

            return classname
        },
        video() {
            let video = this.asset

            // If is array, take first index
            if(Array.isArray(video) && video.length > 0) {
                return video[0]
            }

            else if(video && video.url) {
                return video
            }

            else {
                return false
            }
        },
        placeholder() {
            return this.lazy ? `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9'%3E%3C/svg%3E` : undefined
        },
    },
    methods: {
        loaded() {
            this.isLoaded = true
        },
    },
    watch: {
        isLoaded(isLoaded) {
            if(isLoaded) {
                this.$emit('loaded')
            }
        },
    },
    beforeUnmount() {
        this.scrollTrigger.kill()
    },
};

</script>

<style lang="scss">

.o-video {

    video {
        display: block;
        width: 100%;
        height: auto;
    }
}

</style>
