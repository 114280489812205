<template>
    <component
        v-if="assetComponent"
        :is="url ? 'router-link' : 'div'"
        :to="url ? url : undefined"
        ref="el"
        class="o-asset"
        :class="[
            { '-cover' : cover },
            { '-ratio' : ratio }
        ]"
        :style="styles"
        v-cursor.click="!!url"
    >
        <component
            :is="assetComponent"
            v-bind="props"
        />
    </component>
</template>

<script>

import AppImage from 'objects/AppImage'
import AppVideo from 'objects/AppVideo'
import AppLottie from 'objects/AppLottie'
import VimeoPlayer from 'objects/VimeoPlayer'

import { MIME_TYPES } from 'src/constants'

export default {
    name: 'Asset',
    props: {
        asset: {
            type: [Array, Object],
            default: undefined
        },
        vimeoID: {
            type: String,
            default: undefined
        },
        videoControls: {
            type: Boolean,
            default: false
        },
        videoLoop: {
            type: Boolean,
            default: true
        },
        cover: {
            type: Boolean,
            default: false,
        },
        ratio: {
            type: Number,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
    },
    computed: {
        isVimeo() {
            return !!this.vimeoID
        },
        isLottie() {
            return this.mimeType === MIME_TYPES.JSON
        },
        assetComponent() {

            if(this.isVimeo) {
                return VimeoPlayer
            }

            if(this.mimeType === MIME_TYPES.JPEG || this.mimeType === MIME_TYPES.PNG) {
                return AppImage
            }

            if(this.mimeType === MIME_TYPES.VIDEO) {
                return AppVideo
            }

            if(this.isLottie) {
                return AppLottie
            }

            return false
        },
        mimeType() {

            if(this.isVimeo) {
                return false
            }

            const asset = this.asset

            // If is array, take first index
            if(Array.isArray(asset) && asset.length > 0) {
                return asset[0]?.mimeType
            }

            return asset?.mimeType
        },
        props() {
            const props = {}
            if(this.isVimeo) {
                props.vimeoID = this.vimeoID
                props.controls = !!this.videoControls
                props.loop = !!this.videoLoop
                props.cover = this.cover || this.ratio !== null
            } else {
                props.asset = this.asset

                if(!this.isLottie) {
                    props.cover = this.cover || this.ratio !== null
                }
            }

            return props
        },
        styles() {
            return this.ratio !== null ? `--asset-ratio: ${1/this.ratio};` : undefined
        }
    },
};

</script>

<style lang="scss">


.o-asset {
    display: block;
    width: 100%;
    overflow: hidden;

    @at-root a#{&} {

        > * {
            transition: transform $speed-medium $easing;
        }

        &:hover {

            > * {
                transform: scale(1.25);
                transition: transform 5s ease-in-out;
            }
        }
    }

    &.-cover,
    &.-ratio {
        @include asset-fill;
    }

    &.-ratio {
        --asset-ratio: 16/9;

        height: 0;
        padding-top: calc(100% * var(--asset-ratio));

        > * {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
</style>
