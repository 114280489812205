<template>
    <div
        v-if="animation"
        ref="el"
        class="o-lottie"
    ></div>
</template>

<script>

import lottie from 'lottie-web'

export default {
    name: 'AppLottie',
    props: {
        asset: {
            type: [Array, Object],
            required: true
        },
    },
    mounted() {
        this.lottie = lottie.loadAnimation({
            autoplay: true,
            container: this.$refs.el,
            loop: true,
            path: this.animation.url,
            renderer: 'svg',
        })
    },
    computed: {

        animation() {
            let animation = this.asset

            // If is array, take first index
            if(Array.isArray(animation) && animation.length > 0) {
                return animation[0]
            }

            else if(animation && animation.url) {
                return animation
            }

            else if(this.url) {
                return {
                    url: this.url,
                    title: this.title,
                    width: false,
                    height: false,
                }
            }

            else {
                return false
            }
        },
    }
};

</script>

<style lang="scss">

.o-lottie {

    svg {
        display: block;
    }
}

</style>
