<template>
    <ul class="o-categories">
        <li
            v-for="cat in categories"
            :key="`categories-${cat.id}`"
            class="o-categories_item"
        >
            <btn
                :tag="button ? 'button' : 'span'"
                :dark="dark"
                :small="small"
                :label="cat.title"
                @click.prevent="click(cat.id)"
                :class="{ 'is-active' : cat.id === selectedID }"
                v-cursor.click="button"
            />
        </li>
    </ul>
</template>

<script>

import Btn from 'components/Btn'

export default {
    name: 'Categories',
    emits: ['click'],
    components: {
        Btn,
    },
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        button: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        selectedID: null
    }),
    methods: {
        click(id) {
            if(!this.button) {
                return
            }

            this.selectedID = this.selectedID !== id ? id : null
            this.$emit('click', this.selectedID)
        }
    }
};

</script>

<style lang="scss">

.o-categories {
    display: flex;
    flex-wrap: wrap;
    gap: calc(0.5 * var(--unit));
    @include reset-list;
}

</style>
