<template>
    <component
        v-if="!!$slots.default || !!$slots.before || !!$slots.after"
        ref="el"
        :is="tag"
        :class="className"
    >
        <slot name="before"></slot>
        <slot>
            <div v-html="text"></div>
        </slot>
        <slot name="after"></slot>
    </component>
    <component
        v-else
        ref="el"
        :is="tag"
        v-html="text"
        :class="className"
    />
</template>

<script>

import { ScrollTrigger, SplitText } from 'src/gsap'

export default {
    name: 'AnimText',
    props: {
        text: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'div',
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        align: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'lines'
        },
        cms: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => {}
        },
    },
    data: () => ({
        opts: {
            inDuration: 1,
            inDelta: 1,
            inDelay: 0,
            startWindow: '80%',
            once: false
        },
        isVisible: false,
    }),
    created() {
        this.opts = {...this.opts, ...this.options}
        this.isVisible = this.visible
    },
    mounted() {

        if(this.splitType) {
            // Split text
            this.initSplits()

            // Resize event
            window.addEventListener('resizeEnd', this.onResize = () => this.resize())
        }

        if(this.visible) {
            this.show()
        }

        if(this.reveal && !this.visible) {
            this.scrollTrigger = ScrollTrigger.create({
                trigger: this.$refs.el,
                start: `top ${this.opts.startWindow}`,
                once: this.opts.once,
                onEnter: () => {
                    this.show()
                }
            })
        }
    },
    methods: {
        show() {
            this.isVisible = true
        },
        hide() {
            this.isVisible = false
        },
        initSplits() {

            if(!this.splitType) {
                return
            }

            this.split = new SplitText(this.$refs.el, {
                type: this.splitType,
                charsClass: 'o-at_c',
                wordsClass: 'o-at_w',
                linesClass: 'o-at_l',
                reduceWhiteSpace: false,
            })

            // Add number of lines props to container
            const totalLines = this.split.lines.length
            this.$refs.el.style.setProperty('--at-lines-count', totalLines);

            // Add line index to each lines
            this.split.lines.forEach(($line, i) => {
                $line.style.setProperty('--at-line-index', i)
            })

            // Add words index to each char
            const totalWords = this.split.words.length
            if(totalWords > 0) {
                this.$refs.el.style.setProperty('--at-words-count', totalWords);
                this.split.words.forEach(($char, i) => {
                    $char.style.setProperty('--at-word-index', i)
                })
            }
        },
        revertSplits() {
            this.split.revert()
        },
        resize() {
            this.revertSplits()
            this.initSplits()
        }
    },
    computed: {
        className() {
            let classname = 'o-at'

            // Types
            classname += ` -${this.type}`

            if(this.cms) {
                classname += ' t-cms'
            }

            if(this.align !== null) {
                classname += ` -${this.align}`
            }

            if(this.isVisible) {
                classname += ' is-visible'
            }

            return classname
        },
        splitType() {
            let splitType = false

            if(this.type === 'words') {
                splitType = 'lines,words'
            } else if(this.type === 'lines') {
                splitType = 'lines'
            }

            return splitType
        }
    },
    watch: {
        visible(visible) {
            if(visible) {
                this.show()
            } else {
                this.hide()
            }
        },
    },
    unmounted() {

        if(this.scrollTrigger) {
            this.scrollTrigger.kill()
        }

        if(this.splitType) {
            // Resize event
            window.removeEventListener('resizeEnd', this.onResize)
        }
    }
};

</script>

<style lang="scss">

.o-at {
    --at-lines-count: 1;
    --at-line-index: 0;
    --at-words-count: 1;
    --at-word-index: 0;

    --at-duration-in: 1s;
    --at-easing-in: #{$easing};
    --at-delay-in: 0s;

    --at-duration-out: calc(.5 * var(--at-duration-in));
    --at-easing-out: #{$easing};
    --at-delay-out: 0s;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    p,
    br {
        width: 100%;
    }

//    * {
//        line-height: inherit;
//        font-size: inherit;
//        font-weight: inherit;
//    }

    &.-center {
        justify-content: center;
    }

    &.-right {
        justify-content: right;
    }

    // Lines and words
    &.-lines {

        .o-at_l {
            opacity: 0;
            transform: translate(0, 100%);
            will-change: opacity, transform;

            transition-property: opacity, transform;
            transition-duration: var(--at-duration-out);
            transition-timing-function: var(--at-easing-out);
            // transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-lines-count) - var(--at-line-index))/var(--at-lines-count)) + var(--at-delay-out));
            transition-delay: calc(var(--at-line-index) * var(--at-duration-out)/4 + var(--at-delay-out));
        }
    }

    &.-words {

        .o-at_w {
            opacity: 0;
            transform: translate(0, 100%);
            will-change: opacity, transform;

            transition-property: opacity, transform;
            transition-duration: var(--at-duration-out);
            transition-timing-function: var(--at-easing-out);
            // transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-words-count)/10 - var(--at-word-index))/10) + var(--at-delay-out));
            transition-delay: calc(var(--at-word-index) * var(--at-duration-out)/15 + var(--at-delay-out));
        }

        .o-icon {
            opacity: 0;
            transform: translate(0, 100%) rotateY(90deg);

            transition-property: opacity, transform;
            transition-duration: var(--at-duration-out);
            transition-timing-function: var(--at-easing-out);
            // transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-words-count)/10 - var(--at-word-index))/10) + var(--at-delay-out));
            transition-delay: calc(var(--at-word-index) * var(--at-duration-out)/15 + var(--at-delay-out));

            @for $i from 1 through 25 {

                &:nth-child(#{$i}) {
                    --at-word-index: #{$i - 1};
                }
            }
        }
    }

    // Block
    &.-block {
        opacity: 0;
        transform: translate(0, 2em);

        transition-property: opacity, transform;
        transition-duration: var(--at-duration-out);
        transition-timing-function: var(--at-easing-out);
        transition-delay: var(--at-delay-out);
    }


    html.is-ready &.is-visible {

        // Lines
        &.-lines {

            .o-at_l {
                opacity: 1;
                transform: translate(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: var(--at-duration-in);
                // transition-delay: calc(var(--at-duration-in)/2 * (var(--at-line-index)/var(--at-lines-count)) + var(--at-delay-in));
                transition-delay: calc(var(--at-line-index) * var(--at-duration-in)/4 + var(--at-delay-in));
            }
        }

        &.-words {

            .o-at_w {
                opacity: 1;
                transform: translate(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: var(--at-duration-in);
                // transition-delay: calc(var(--at-duration-in)/2 * (var(--at-word-index)/10) + var(--at-delay-in));
                transition-delay: calc(var(--at-word-index) * var(--at-duration-in)/15 + var(--at-delay-in));
            }

            .o-icon {
                opacity: 1;
                transform: translate(0) rotateY(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: calc(1.25 * var(--at-duration-in));
                // transition-delay: calc(var(--at-duration-in)/2 * (var(--at-word-index)/10) + var(--at-delay-in));
                transition-delay: calc(var(--at-word-index) * var(--at-duration-in)/15 + var(--at-delay-in));
            }
        }

        // Block
        &.-block {
            opacity: 1;
            transform: translate(0);

            transition-timing-function: var(--at-easing-in);
            transition-duration: var(--at-duration-in);
            transition-delay: var(--at-delay-in);
        }
    }
}

.o-at_l {
    overflow: hidden;

    .o-at.t-link & {
        padding-right: .25em;
    }
}

.o-at_w {
}

.o-at_c {}


</style>
