/*
** Cursor states
*/

// State
const state = {
    states: [],
}

// Getters
const getters = {
    hasState: (state, getters) => getters.totalStates > 0,
    totalStates: state => state.states.length,
    currentState: (state, getters) =>  getters.totalStates > 0 ? state.states[getters.totalStates - 1] : false
}

// Actions
const actions = {
    addState(store, value) {
        store.commit('addState', value)
    },
    removeState(store, value) {
        if(store.getters.currentState === value) {
            store.commit('removeState')
        }
    },
    resetState(store) {
        store.commit('resetState')
    },
}

// Mutations
const mutations = {
    addState(state, value) {
        state.states.push(value)
    },
    removeState(state) {
        state.states.pop()
    },
    resetState(state) {
        state.states = []
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
