import { createStore } from 'vuex';

import cursor from './modules/cursor';
import global from './modules/global';
import loader from './modules/loader';
import pages from './modules/pages';
import project from './modules/project';

const store = createStore({
    modules: {
        cursor,
        global,
        loader,
        pages,
        project,
    },
    strict: process.env.NODE_ENV !== 'production'
})

export default store
