<template>
    <picture
        v-if="image"
        :class="className"
        ref="el"
    >
        <source
            v-if="image.srcset"
            ref="source"
            :srcset="!lazy ? image.srcset : placeholder"
            :type="image.mimeType"
        >
        <img
            ref="img"
            :src="!lazy ? placeholder : image.url"
            :alt="title ? title : image.title"
            class="o-image_img"
            :width="image.width"
            :height="image.height"
            @load="loaded"
        />
    </picture>
</template>

<script>

import { round } from 'src/utils'

import { ScrollTrigger } from 'src/gsap'

export default {
    name: 'AppImage',
    props: {
        asset: {
            type: [Array, Object],
            default: () => []
        },
        url: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
        reveal: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        isLoaded: false,
        inView: false
    }),
    created() {
        this.isLoaded = !this.lazy
    },
    mounted() {
        if(this.lazy) {

            if(this.srcset) {
                this.$refs.source.srcset = this.srcset
            } else {
                this.$refs.img.src = this.image.url
            }
        }

        this.scrollTrigger = false

        if(this.reveal) {
            this.scrollTrigger = ScrollTrigger.create({
                trigger: this.$refs.el,
                start: 'top 80%',
                once: true,
                onEnter: () => {
                    this.inView = true
                }
            })
        }
    },
    computed: {
        className() {
            let classname = 'o-image'

            if(this.lazy) {
                classname += ' -lazy'

                if(this.isLoaded) {
                    classname += ' is-loaded'
                }
            }

            if(this.reveal) {
                classname += ' -reveal'

                if(this.inView) {
                    classname += ' is-inview'
                }
            }

            return classname
        },
        image() {
            let image = this.asset

            // If is array, take first index
            if(Array.isArray(image) && image.length > 0) {
                return image[0]
            }

            else if(image && image.url) {
                return image
            }

            else if(this.url) {
                return {
                    url: this.url,
                    title: this.title,
                    width: false,
                    height: false,
                }
            }

            else {
                return false
            }
        },
        width() {
            return this.image.width
        },
        height() {
            return this.image.height
        },
        placeholder() {
            return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${this.width} ${this.height}'%3E%3C/svg%3E`
        },
        ratio() {
            if(!this.width || !this.height) {
                return null
            }

            return round(this.width / this.height, 3)
        },
    },
    methods: {
        loaded() {
            this.isLoaded = true
        },
    },
    watch: {
        isLoaded(loaded) {
            if(loaded) {
                this.$emit('loaded')

                if(this.scrollTrigger) {
                    this.scrollTrigger.refresh()
                }
            }
        },
    },
    beforeUnmount() {
        if(this.scrollTrigger) {
            this.scrollTrigger.kill()
        }
    }
};

</script>

<style lang="scss">

.o-image {
    display: block;

    &.-lazy {
        overflow: hidden;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: currentColor;
        opacity: .1;
    }
}

.o-image_img {
    @include img;

    .o-image.-reveal & {
        opacity: 0;
        transform: scale(1.1);
    }

    .o-image.-reveal.is-inview:not(.-lazy) &,
    .o-image.-reveal.is-inview.-lazy.is-loaded & {
        opacity: 1;
        transform: scale(1);
        transition: all $speed-slow $easing;
    }
}

</style>
