<template>
    <span
        ref="el"
        class="o-cursor"
        :class="className"
    >
        <span class="o-cursor_inner"></span>
        <span class="o-cursor_loading">
            <svg viewBox="25 25 50 50" >
                <circle cx="50" cy="50" r="20" stroke-width="2" />
            </svg>
        </span>
    </span>
</template>

<script>

import { mapGetters } from 'vuex'
import { gsap } from 'src/gsap';

export default {
    name: 'AppCursor',
    data: () => ({
        isActive: false,
        isScrolling: false,
    }),
    mounted() {

        this.docElement = document.documentElement

        // Document events
        document.addEventListener('mousemove', e => {
            this.move(e.clientX, e.clientY)
        })

        // Window events
        window.addEventListener('mousedown', () => {
            this.isActive = true
        })

        window.addEventListener('mouseup', () => {
            this.isActive = false
        })
    },
    computed: {
        ...mapGetters({
            state: 'cursor/currentState',
        }),
        className() {
            let className = ''

            if (this.state) {
                className += ` -${this.state}`
            }
            if (this.isActive) {
                className += ' is-active'
            }

            return className
        },
    },
    methods: {
        move(x, y) {
            gsap.to(this.$refs.el, {
                duration: .4,
                x,
                y,
                ease: 'power3.out',
            })
        },
    },
}

</script>

<style lang="scss">

* {
    cursor: none !important;
}

.o-cursor {
    --cursor-color: currentColor;
    --cursor-size: 4em;

    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: var(--cursor-size);
    height: var(--cursor-size);
    margin-top: calc(-0.5 * var(--cursor-size));
    margin-left: calc(-0.5 * var(--cursor-size));
    pointer-events: none;
    transition: color $speed $easing;
    will-change: transform;

    @media (hover: none) {
        display: none;
        visibility: hidden;
    }

    &.-loading {
    }
}

.o-cursor_inner,
.o-cursor_loading {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.o-cursor_inner {
    background-color: var(--cursor-color);
    transform: scale(.2);
    transition: opacity $speed $easing, transform $speed $easing;
    will-change: transform;

    .o-cursor.-click & {
        opacity: .5;
        transform: scale(.5);
    }

    .o-cursor.-hide &,
    .o-cursor.-loading & {
        transform: scale(0);
    }
}

.o-cursor_loading {
    opacity: 0;
    transform: scale(1.2);
    transition: opacity $speed $easing, transform $speed $easing;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        stroke: var(--theme-color-primary);
        fill: none;
        animation: anim-cursor-loader-rotate 2s linear infinite paused;
    }

    circle {
        stroke-dasharray: 150,200;
        stroke-dashoffset: -10;
        animation: anim-cursor-loader-dash 1.5s ease-in-out infinite paused;
        stroke-linecap: round;
    }

    .o-cursor.-loading & {
        transform: scale(1);
        opacity: 1;

        svg,
        circle {
            animation-play-state: running;
        }
    }
}


/*----------  Animations  ----------*/

@keyframes anim-cursor-loader-rotate {
    100% {
        transform: rotate(1turn);
    }
}

@keyframes anim-cursor-loader-dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



</style>
