import store from 'src/store'

// const enter = modifier => {
//     store.dispatch('cursor/addState', modifier, {root: true})
// }

// const leave = modifier => {
//     store.dispatch('cursor/removeState', modifier, {root: true})
// }


const Cursor = {
    created($el, binding) {
        $el.enabled = binding.value !== false

        if($el.enabled) {
            const modifier =  Object.keys(binding.modifiers)[0] || ''

            $el.onEnter = () => store.dispatch('cursor/addState', modifier, {root: true})
            $el.onLeave = () => store.dispatch('cursor/removeState', modifier, {root: true})

            $el.addEventListener('mouseenter', $el.onEnter)
            $el.addEventListener('mouseleave', $el.onLeave)
        }
    },
    beforeUnmounted($el) {

        if($el.enabled) {
            $el.removeEventListener('mouseenter', $el.onEnter)
            $el.removeEventListener('mouseleave', $el.onLeave)
        }
    }
}

export default Cursor
