import { createRouter, createWebHistory } from 'vue-router';
import store from 'src/store';
// import { STATIC_ROUTES } from 'src/constants';

// Views
import * as Components from './templates/views'

// Dynamic Routes from Craft
const dynamicRoutes = window.__initialData__.routes.map(r => ({ ...r, component: Components[r.component] }));

// Static routes
const staticRoutes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    },
]

// Merge dynamic and static routes
const routes = [...dynamicRoutes, ...staticRoutes]

// Create the router with the routes
const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'is-active',
});

// Before each route load
router.beforeEach((to, from, next) => {

    // Load page
    store
        .dispatch('global/loadContent', to)
        .then(() => {
            next()
        })
        .catch(e => {
            console.error(e.message) // eslint-disable-line
            next('/')
        });

});

// Link resolver
const linkResolver = (section, slug=false) => {

    const route = routes.find(r => r.meta && r.meta.section === section)
    let path = route ? route.path : '/'

    if(route && slug) {
        path = path.replace(':slug', slug)
    }

    return path;
};

// Export
export default router;
export { router, routes, linkResolver };
