<template>
    <i class="o-icon">
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
    },
    computed: {
        path() {
            return `/dist/svg/sprite.svg#svg-${this.icon}`;
        },
    },
};

</script>

<style lang="scss">

.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        --icon-height: calc(var(--icon-width) * var(--icon-ratio));

        display: block;
        width: var(--icon-width);
        height: var(--icon-height);
        fill: currentColor;
    }
}


/*----------  SVG sizes  ----------*/

// Logo
.svg-logo {
    --icon-width: 4em;
    --icon-ratio: 1;
}

.svg-arrow-down {
    --icon-width: 1em;
    --icon-ratio: 48/14;
}

.svg-arrow-out {
    --icon-width: .9em;
    --icon-ratio: 25/33;
}

.svg-arrow-left,
.svg-arrow-right {
    --icon-width: 2em;
    --icon-ratio: 31/100;
}

.svg-flag-usa {
    --icon-width: 1em;
    --icon-ratio: 22/32;
}

.svg-globe {
    --icon-width: 3em;
    --icon-ratio: 1;
}

.svg-shape-arrows,
.svg-shape-smiley,
.svg-shape-circle-dots,
.svg-shape-circle-lines {
    --icon-width: 1em;
    --icon-ratio: 1;
}

.svg-shape-heart {
    --icon-width: 1em;
    --icon-ratio: 18/22;
}

</style>
