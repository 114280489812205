<template>
    <app-base/>
</template>

<script>

import 'assets/scss/styles.scss';
import AppBase from 'layout/AppBase';

export default {
    name: 'App',
    components: {
        AppBase
    },
};
</script>

<style>

</style>
