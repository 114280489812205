<template>
    <div
        ref="el"
        class="o-vimeo-player"
        :class="[
            { '-cover' : cover },
            { '-controls': controls}
        ]"
        v-cursor.hide="controls"
    ></div>
    <button
        v-if="controls"
        class="o-vimeo-player_overlay"
        :class="{ 'is-hidden' : hideOverlay }"
        @click="onOverlayClick"
        v-cursor
    >
        <btn
            tag="span"
            label="play"
            :dark="true"
        />
    </button>
</template>

<script>

import Btn from 'components/Btn'

import Player from '@vimeo/player';
import { gsap, ScrollTrigger } from 'src/gsap';

export default {
    name: 'VimeoPlayer',
    components: {
        Btn,
    },
    props: {
        vimeoID: {
            type: String,
            required: true
        },
        controls: {
            type: Boolean,
            required: false
        },
        cover: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
        isReady: false,
        hideOverlay: false,
    }),
    mounted() {
        this.player = new Player(this.$refs.el, {
            id: this.vimeoID,
            autopause: false,
            // autoplay: !this.controls,
            loop: this.controls ? false : this.loop,
            muted: !this.controls,
            color: 'FFFFFF',
            controls: this.controls,
            responsive: true,
        })

        this.player.ready().then(() => {
            this.isReady = true

            if(this.cover) {
                this.$video = this.$refs.el.children[0]
                this.setScale()
                window.addEventListener('resizeEnd', this.onResize = () => this.setScale())
            }

            if(!this.controls) {

                this.scrollTrigger = ScrollTrigger.create({
                    trigger: this.$refs.el,
                    start: 'top bottom',
                    end: 'bottom top',
                    onToggle: ({ isActive }) => {
                        if(isActive) {
                            this.player.play()
                        } else {
                            this.player.pause()
                        }
                    }
                })

                if(!this.loop) {

                    const onEnded = () => {
                        this.scrollTrigger.kill()
                        this.player.off(onEnded)
                    }

                    this.player.on('ended', onEnded)
                }
            }
        })
    },
    methods: {
        setScale() {

            if(!this.isReady) {
                return
            }

            const width = this.$refs.el.offsetWidth
            const height = this.$refs.el.offsetHeight
            const ratio = width/height

            const videoWidth = this.$video.offsetWidth
            const videoHeight = this.$video.offsetHeight
            const videoRatio = videoWidth/videoHeight

            const scale = ratio > videoRatio ? width / videoWidth : height / videoHeight

            gsap.set(this.$video, {
                xPercent: -50,
                yPercent: -50,
                scale,
            })
        },
        onOverlayClick() {
            this.hideOverlay = true

            if(this.isReady) {
                this.player.play()
            }
        }
    },
    beforeUnmount() {
        if(!this.controls) {
            this.scrollTrigger?.kill()
        }
    },
    unmounted() {
        window.removeEventListener('resizeEnd', this.onResize)
    }
};

</script>

<style lang="scss">

.o-vimeo-player {

    &:not(.-controls) {
        pointer-events: none;
    }

    &.-controls {

        &,
        & > * {
            cursor: pointer !important;
        }
    }

    &.-cover {
        overflow: hidden;

        > * {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }
}

.o-vimeo-player_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color-darkest, .5);
    }

    &.is-hidden {
        pointer-events: none;
        opacity: 0;
        transition: opacity $speed $easing;
    }
}

</style>
