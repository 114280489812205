export const SECTION_HANDLES = Object.freeze({
    HOME: 'home',
    ABOUT: 'about',
    PROJECTS: 'projects',
    PROJECT: 'project',
});

export const GQL_QUERIES = Object.freeze({
    GENERAL: 'general',
});

export const BUILDER_TYPE_HANDLES = Object.freeze({
    ASSET_LARGE: 'assetLarge',
    ASSETS_GRID: 'assetsGrid',
    TEXT: 'text',
    THEATER: 'theater',
});

export const MIME_TYPES = Object.freeze({
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    VIDEO: 'video/mp4',
    JSON: 'application/json',
});

export const ASSET_DISPLAY = Object.freeze({
    LARGE: 'large',
    HALF: 'half',
    FULLSCREEN: 'fullscreen',
});
