import { fetchApi } from 'src/graphql/config';
import queries from 'src/graphql/queries';
import parseData from 'src/store/parse';
import { SECTION_HANDLES, GQL_QUERIES } from 'src/constants'

/*
 * Global
 */

// const initialData = window.__initialData__


// State
const state = {
    nav: [],
    projectTypes: [],
    footer: {},
    currentPage: {},
    currentPageName: null,
    isReady: false,
    themes: []
};

// Getters
const getters = {
    hasCurrentPage: state => !(Object.keys(state.currentPage).length === 0 && state.currentPage.constructor === Object),
    hasTheme: (state, getters) => getters.totalThemes > 0,
    totalThemes: state => state.themes.length,
    currentTheme: (state, getters) =>  getters.totalThemes > 0 ? state.themes[getters.totalThemes - 1] : false
};

// Actions
const actions = {
    // Initial load for navigation, globals, etc...
    initLoad(store) {

        return Promise.all([
            store.dispatch('loadGeneral'),
            store.dispatch('project/preloadAll', null, {root: true})
        ])
        .catch(e => {
            console.error(e.message) // eslint-disable-line
        })
    },
    loadGeneral(store) {
        return fetchApi(queries[GQL_QUERIES.GENERAL])
            .then(data => {
                data = parseData(GQL_QUERIES.GENERAL, data)
                store.commit('setNav', data.nav);
                store.commit('setFooter', data.footer);
                store.commit('setProjectTypes', data.projectTypes);
            })
            .catch(e => {
                console.error(e.message) // eslint-disable-line
            })
    },
    loadContent(store, to) {

        return new Promise((resolve, reject) => {

            // Timer in seconds
            const timer = new Date

            // Start loader
            store.dispatch('loader/startLoad', null, {root: true});

            // Load page content
            let fetchContent;
            const handle = to.meta.section;
            const singleEntry = to.meta.single;

            // Empty promise if nothing to load (static page)
            if (typeof handle === 'undefined') {
                fetchContent = new Promise(r => r({ static: true }))

            // For single entries, load page with handle
            } else if (singleEntry) {
                fetchContent = store.dispatch('pages/loadSingle', handle, {root: true});

            } else if (handle === SECTION_HANDLES.PROJECT) {
                const slug = to.params.slug;
                fetchContent = store.dispatch('project/loadSingle', slug, {root: true});

            } else {
                const slug = to.params.slug;
                fetchContent = store.dispatch('pages/loadEntry', { handle, slug }, {root: true});
            }

            fetchContent
                .then(page => {

                    // Duration in ms of the fetchContent + 600ms for the loader cover delay (see loader component) + 10ms for latency
                    // let delay = store.rootState.loader.isVisible ? (timer - new Date) + 800 : 0
                    let delay = (timer - new Date) + 600
                    delay = delay < 0 ? 0 : delay

                    setTimeout(() => {
                        store.commit('setPage', {});
                        store.commit('setPageName', to.name)
                        resolve(page);

                        // Timeout to let template to render data
                        setTimeout(() => {
                            // Set page after router page has updated to avoid js errors
                            store.commit('setPage', page);
                            store.dispatch('loader/endLoad', null, {root: true});
                        }, 10);

                    }, delay);
                })
                .catch(e => {

                    reject(e)
                    store.dispatch('loader/endLoad', null, {root: true})
                })

        });
    },
    updateReadyState(store, ready) {
        store.commit('setIsReady', ready);
    },
    addTheme(store, theme) {
        store.commit('addTheme', theme)
    },
    removeTheme(store, theme) {
        if(store.getters.currentTheme.bg === theme.bg) {
            store.commit('removeTheme')
        }
    },
    resetTheme(store) {
        store.commit('resetTheme')
    },
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.nav = nav;
    },
    setProjectTypes(state, projectTypes) {
        state.projectTypes = projectTypes;
    },
    setFooter(state, footer) {
        state.footer = footer;
    },
    setPage(state, page) {
        state.currentPage = page;
    },
    setPageName(state, name) {
        state.currentPageName = name
    },
    setIsReady(state, ready) {
        state.isReady = ready
    },
    addTheme(state, value) {
        state.themes.push(value)
    },
    removeTheme(state) {
        state.themes.pop()
    },
    resetTheme(state) {
        state.themes = []
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
