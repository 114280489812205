/*
** Debounce function
*/
export const debounce = (callback, delay = 250) => {
    let timeoutId
    return (...args) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            timeoutId = null
            callback(...args)
        }, delay)
    }
}


/*
** Round number with n decimals
*/

export const round = (x, n=2) => {
  const decimals = 10 ** n
  return Math.round(x * decimals)/decimals
}


/*
** Reduced motion var
*/

export const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches


/*
** Add resizEnd event to window
*/

export const resizeEnd = () => {
    const resizeEnd = new Event('resizeEnd');

    window.addEventListener('resize', debounce(() => {
        window.dispatchEvent(resizeEnd)
    }, 200));
}


/*
** Format date with hours AM/PM
*/

export const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}${minutes == '00' ? '' : `:${minutes}`}${ampm}`;
}
