/*
 * Craft GraphQL Snippets
 */

/**********************************
 *
 *
 *  DEFAULT
 *
 *
 **********************************/

// Default data
const defaultData = `
id
url
`

// Default entry
const entry = `
id
uri
title
sectionHandle
`


// Address field
const address = `
address {
    ...on address_BlockType {
        label
        gmap
    }
}
`

// Seo
const seo = `
seo {
    title
    description
}
`



export default {
    seo,
    defaultData,
    entry,
    address,
}
