<template>
    <div
        class="l-grid-debugger | grid"
        :class="{ 'is-visible' : isVisible }"
    >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>

<script>

export default {
    name: 'GridDebugger',
    data: () => ({
        isVisible: false
    }),
    mounted() {
        let ctrlDown = false

        document.addEventListener('keydown', (e) => {
            if(e.key == 'Control') {
                ctrlDown = true;
            } else {
                if(ctrlDown && e.key == 'g') {
                    this.isVisible = !this.isVisible
                }
            }
        })

        document.addEventListener('keyup', (e) => {
            if(e.key == 'Control') {
                ctrlDown = false
            }
        })
    }
}

</script>

<style lang="scss">

.l-grid-debugger {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    pointer-events: none;

    span {
        width: grid-space(1/12);
        background-color: rgba(#B8B8B8, .25);
    }

    &:not(.is-visible) {
        visibility: hidden;
    }
}

</style>
