<template>
    <component
        :is="tag"
        class="l-section"
    >
        <div class="l-section_inner | grid">
            <header
                v-if="!!$slots.header"
                class="l-section_header"
            >
                <slot name="header"></slot>
            </header>

            <div
                v-if="!!$slots.default"
                class="l-section_content"
            >
                <slot></slot>
            </div>
        </div>
    </component>
</template>

<script>

export default {
    name: 'AppSection',
    props: {
        tag: {
            type: String,
            default: 'section'
        },
    }
};

</script>

<style lang="scss">

.l-section {
    margin-bottom: spacer(6);

    &:first-child {
        padding-top: calc(var(--header-height) + spacer(2));
    }

    @media #{md("sm")} {
        margin-bottom: spacer(8);
    }
}

.l-section_inner {}

.l-section_header {
    margin-bottom: spacer(5);
}

</style>
