<template>
    <ul
        ref="el"
        class="c-team"
        @touchmove="move"
        @mousemove="move"
    >
        <li
            v-for="(member, i) in members"
            :key="`member-${i}`"
            class="c-team_member"
            @mouseenter="show()"
            @mouseleave="hide()"
            @touchstart="show()"
            @touchend="hide()"
            @mousemove="e => updateFrame(e, i)"
            @touchmove="e => updateFrame(e, i)"
            v-cursor.hide="sequences[i] !== false"
        >
            <squeeze-box
                v-if="member.desc"
                class="c-team_inner"
            >
                <template #toggler>
                    <anim-text
                        tag="h3"
                        :text="member.name"
                        class="c-team_name"
                    />
                    <anim-text
                        tag="h4"
                        :text="`${member.job} <span class='c-team_icon'></span>`"
                        class="c-team_job"
                    />
                </template>
                <div
                    v-html="member.desc"
                    class="c-team_desc | t-body-big"
                ></div>
            </squeeze-box >
            <div
                v-else
                class="c-team_inner"
            >
                <header class="t-h1 -lower">
                    <anim-text
                        tag="h3"
                        :text="member.name"
                        class="c-team_name"
                    />
                    <anim-text
                        tag="h4"
                        :text="`${member.job} <span class='c-team_icon'></span>`"
                        class="c-team_job"
                    />
                </header>
            </div>
        </li>
    </ul>
    <div
        ref="photo"
        class="c-team_photo"
    >
        <canvas
            ref="canvas"
            class="c-team_canvas"
        ></canvas>
    </div>
</template>

<script>

import AnimText from 'objects/AnimText';
import SqueezeBox from 'components/SqueezeBox';

import { gsap } from 'src/gsap';

export default {
    name: 'TeamList',
    props: {
        members: {
            type: Array,
            default: () => []
        },
    },
    data: () => ({
        preloadStep: 8, // MUST be a power of 2
        sequences: [],
        activeMemberIndex: -1
    }),
    created() {
        this.loadSequences()
    },
    mounted() {
        this.$members = Array.from(this.$refs.el.children)

        this.$photo = this.$refs.photo;
        this.$canvas = this.$refs.canvas;
        this.context = this.$canvas.getContext('2d');

        // this.loadPhotos()
        this.setSizes()

        window.addEventListener('resizeEnd', this.onResize = () => this.setSizes())
    },
    components: {
        AnimText,
        SqueezeBox,
    },
    methods: {
        loadSequences() {
            this.members.forEach((member, i) => {
                if(member.sequence.length === 0) {
                    this.sequences[i] = false
                } else {
                    this.sequences[i] = []
                    member.sequence.forEach((frame, j) => {
                        this.sequences[i].push({
                            image: new Image(),
                            isLoaded: false,
                        })

                        this.sequences[i][j].image.src = frame.url

                        // Callback on image load
                        const imageLoad = () => {

                            // Set sequence loaded
                            this.sequences[i][j].isLoaded = true;

                            this.sequences[i][j].image.removeEventListener('load', imageLoad)
                        }

                        this.sequences[i][j].image.addEventListener('load', imageLoad)
                    })
                }
            })
        },
        setSizes() {
            this.windowWidth = window.innerWidth

            this.$canvas.width = this.$photo.offsetWidth
            this.$canvas.height = this.$photo.offsetHeight
        },
        show() {
            gsap.to(this.$refs.canvas, {
                opacity: 1,
                scale: 1,
                duration: .4,
                ease: 'power3.out'
            })
        },
        hide() {
            gsap.to(this.$refs.canvas, {
                opacity: 0,
                scale: 0,
                duration: .4,
                ease: 'power3.in'
            })
        },

        move(e) {
            e.preventDefault()

            const eTouch = typeof e.touches !== 'undefined'

            if(eTouch) {
                e = e.touches[0]
            }

            const rect = this.$refs.el.getBoundingClientRect()
            const x = e.pageX - rect.left
            const xPercent = -100 * (x/this.windowWidth - .5)
            const y = eTouch ? e.pageY - rect.top - window.scrollY : e.clientY - rect.top
            gsap.to(this.$photo, {
                x,
                y,
                xPercent,
                duration: .6,
                ease: 'power3.out'
            })
        },
        updateFrame(e, index) {
            e.preventDefault()

            const eTouch = typeof e.touches !== 'undefined'

            if(eTouch) {
                e = e.touches[0]
            }

            const sequence = this.sequences[index]

            if(this.activeMemberIndex !== index) {
                this.context.clearRect(0, 0, this.$canvas.width, this.$canvas.height)
            }

            this.activeMemberIndex = index

            if(sequence === false) {
                return
            }

            const rect = this.$members[index].getBoundingClientRect()
            const x = (e.pageX - rect.left)/rect.width
            const frameIndex = Math.floor(x * sequence.length)

            if(sequence[frameIndex]?.isLoaded) {
                this.context.clearRect(0, 0, this.$canvas.width, this.$canvas.height)
                this.context.drawImage(sequence[frameIndex].image, 0, 0, this.$canvas.width, this.$canvas.height)
            }
        }
    },
    unmounted() {
        window.removeEventListener('resizeEnd', this.onResize)
    }
};

</script>

<style lang="scss">

.c-team {
    @include reset-list;
    couter-reset: team-member;
    display: grid;

    @media #{md("sm")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.c-team_member {
    counter-increment: team-member;
    padding: spacer(2) var(--grid-gutter-half);
}

.c-team_inner {

    &:before {
        content: counters(team-member, "", decimal-leading-zero);
        position: absolute;
        top: .75em;
        left: -1.5em;
        display: inline-block;
        font-size: .75em;
    }
}

.c-team_job,
.c-team_name {
    display: block;
    font-size: inherit;
    font-weight: inherit;
}

.c-team_desc {}

.c-team_photo {
    z-index: 2000;
    position: absolute;
    top: -12em;
    left: -12em;
    width: 24em;
    height: 24em;
    pointer-events: none;

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: scale(0);
    }
}


</style>
