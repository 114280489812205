<template>
    <article
        v-if="data"
        ref="el"
        class="b-theater | grid"
        v-color="{ bg: data.color, light: data.lightColor }"
    >
        <div class="b-theater_inner">
            <asset
                v-if="asset"
                :asset="asset.assetFull"
                :vimeoID="asset.vimeoID"
                :videoControls="asset.videoControls"
                :videoLoop="asset.videoLoop"
                class="b-theater_asset"
                :class="`-${asset.display}`"
            />
            <div
                v-if="data.headline || data.body"
                class="b-theater_content"
            >
                <anim-text
                    tag="h3"
                    :text="data.headline"
                    class="b-theater_title | t-h3"
                />
                <anim-text
                    :cms="true"
                    :text="data.body"
                    class="b-theater_body | t-body"
                />
            </div>
        </div>
    </article>
</template>

<script>

import AnimText from 'objects/AnimText';
import Asset from 'objects/Asset';

export default {
    name: 'BlockTheater',
    components: {
        AnimText,
        Asset,
    },
    props: {
        data: Object,
    },
    computed: {
        asset() {
            const asset = this.data.children
            return asset.length > 0 ? asset[0] : false
        }
    },
};

</script>

<style lang="scss">

.b-theater {
    padding-top: spacer(8);
    padding-bottom: spacer(8);
    background-color: var(--b-theater-bg);
}

.b-theater_inner {
}

.b-theater_asset {

    &.-large {
        width: 100%;
    }

    &.-fullscreen {
        width: 100vw;
        margin-right: calc(-1 * var(--grid-gutter));
        margin-left: calc(-1 * var(--grid-gutter));
    }

    @media #{md("xs")} {
        padding-right: grid-space(1/12, 1);
        padding-left: grid-space(1/12, 1);

        &.-half {
            width: grid-space(1/2);
        }
    }
}

.b-theater_content {
    padding-top: spacer(4);

    @media #{md("xs")} {
        margin-right: grid-space(1/12, 1);
        margin-left: grid-space(1/12, 1);
    }

    @media #{md("sm")} {
        width: grid-space(1/2);
    }

    @media #{md("md")} {
        width: grid-space(1/3);
    }
}

.b-theater_title {

    & + .b-theater_body {
        margin-top: spacer(1);
    }
}

</style>

