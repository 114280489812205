<template>
    <app-section>
        <template #header>
            <anim-text
                tag="h1"
                :text="page.title"
                class="p-about_title | t-h1 -primary"
            >
                <template #before>
                    <icon icon="arrow-right" />
                </template>
            </anim-text>
        </template>
        <div class="p-about_heading | t-h1 -lower">
            <anim-text type="words">
                <p>
                    <span class="t-highlight">Study Hall</span> <icon icon="shape-smiley" /> is a small team on purpose. We love working directly with brave founders and decision-makers to create <icon icon="shape-circle-lines" /> <span class="t-highlight">beautiful, thoughtful work.</span>
                </p>
            </anim-text>
            <anim-text
                type="words"
                align="right"
            >
                <p>
                    On top of it all, we <icon icon="shape-heart" title="love" /> <span class="t-highlight">love helping people</span> <br>
                    — we’re a <icon icon="shape-circle-dots" /> <span class="t-highlight">relationship-driven</span> <br> <span class="t-highlight">company</span> that puts
                </p>
            </anim-text>
            <anim-text type="words">
                <p>
                    communication and collaboration at the <icon icon="shape-arrows" /> <span class="t-highlight">highest altitude.</span>
                </p>
            </anim-text>
        </div>
    </app-section>
    <app-section>
        <div
            v-if="page.body"
            class="o-intro"
        >
            <anim-text
                :text="page.body"
                :cms="true"
                type="block"
                class="o-intro_body | t-body-big"
            />

            <dl
                v-if="page.list.length > 0"
                class="o-intro_list | t-small"
            >
                <template
                    v-for="(item, i) in page.list"
                    :key="`intro-item-${i}-label`"
                >
                    <anim-text
                        type="block"
                        class="o-intro_item"
                    >
                        <dt class="o-intro_label">{{ item.label }}</dt>
                        <dd class="o-intro_desc">{{ item.body }}</dd>
                    </anim-text>
                </template>
            </dl>
        </div>
    </app-section>

    <app-section>
        <template #header>
            <anim-text
                tag="h2"
                :text="page.services.headline"
                class="t-h1"
            />
        </template>
        <div class="o-services">
            <div
                v-for="(service, i) in page.services.services"
                :key="`services-${i}`"
                class="o-services_items | t-body-big"
            >
                <anim-text
                    tag="h3"
                    class="o-services_title"
                >
                    {{ service.headline }}
                </anim-text>
                <ul class="o-services_list">
                    <anim-text
                        v-for="(s, j) in service.list"
                        :key="`services-${i}-${j}`"
                        tag="li"
                        class="o-services_item"
                    >
                        {{ s.item }}
                    </anim-text>
                </ul>
            </div>
        </div>
    </app-section>

    <app-section>
        <template #header>
            <anim-text
                tag="h2"
                :text="page.teamTitle"
                class="t-h1"
            />
        </template>

        <team-list :members="page.teamMembers" />

        <jobs-list :jobs="page.jobs" />
    </app-section>

</template>

<script>

import AnimText from 'objects/AnimText';
import AppSection from 'layout/AppSection';
import Icon from 'objects/Icon';
import JobsList from 'components/JobsList';
import TeamList from 'components/TeamList';

export default {
    name: 'About',
    components: {
        AnimText,
        AppSection,
        Icon,
        JobsList,
        TeamList,
    },
    props: {
        page: Object,
    },
};

</script>

<style lang="scss">

.p-about {
    --root-color    : #{$color-light};
    --root-bg       : #{$color-dark};
}

.p-about_title {

    .o-icon {
        margin-right: .5em;
    }
}

.p-about_heading {

    .o-icon,
    .t-highlight {
        color: var(--root-color-primary);
    }

    .o-icon {
        margin-right: .15em;
        margin-left: .15em;
        color: var(--root-color-primary);
    }

    .o-at {

        &:nth-child(1) {

            .o-at_l .t-highlight:first-child .o-at_w:first-child {
                text-transform: capitalize;
            }
        }

        &:nth-child(2) {
            --at-delay-in: 1.8s;
        }

        &:nth-child(3) {
            --at-delay-in: 3s;
        }
    }

    .o-at_l {
        overflow: hidden;
    }

    @media #{md("sm")} {

        .o-at {

            &:not(:first-child) {
                width: 100%;
                padding-top: spacer(4);
            }

            &:nth-child(2) {
                padding-left: grid-space(3/12);
                text-align: right;
            }

            &:nth-child(3) {
                width: grid-space(8/12);
                margin-left: grid-space(2/12, 1);
            }
        }
    }
}


/*=============================
=            Intro            =
=============================*/

.o-intro {
    display: flex;
    gap: spacer(2) var(--grid-gutter);

    @media #{md("sm", "max")} {
        flex-direction: column;
    }

    @media #{md("sm")} {
        align-items: flex-start;
        justify-content: space-between;
        padding-right: grid-space(1/12, 1);
        padding-left: grid-space(1/12, 1);
    }

    @media #{md("md")} {
        padding-left: grid-space(2/12, 1);
    }
}

.o-intro_body {

    @media #{md("xs")} {
        padding-right: grid-space(1/6, 1);
    }

    @media #{md("sm")} {
        width: grid-space(7/10);
        padding-right: grid-space(1/10, 1);
        padding-left: 0;
    }

    @media #{md("md")} {
        width: grid-space(7/9);
        padding-right: grid-space(1/9, 1);
    }
}

.o-intro_list {
    counter-reset: intro-item;
    font-family: ff("serif");

    @media #{md("sm", "max")} {
        display: flex;
        flex-wrap: wrap;
        gap: spacer(2) var(--grid-gutter);
    }

    @media #{md("sm")} {
        width: grid-space(3/10);
    }

    @media #{md("md")} {
        width: grid-space(2/9);
    }
}

.o-intro_item {
    counter-increment: intro-item;

    @media #{md("sm", "max")} {
        width: grid-space(1/3);
        padding-right: 1em;
    }

    @media #{md("xs", "max")} {
        width: grid-space(1/2);
    }

    @media #{md("sm")} {

        &:not(:first-child) {
            margin-top: spacer(3);
        }
    }
}

.o-intro_label {
    margin-bottom: spacer(1);
    line-height: 1;
    font-weight: 700;

    &:before {
        content: counter(intro-item);
        position: absolute;
        bottom: .1em;
        left: calc(-.75 * var(--grid-gutter));
        font-size: .75em;
        font-family: ff("sans");
        font-weight: 400;
    }

    @media #{md("sm")} {

        &:before {
            left: -2em;
        }
    }
}
.o-intro_desc {
    font-weight: 200;
}



/*================================
=            Services            =
================================*/

.o-services {
    display: grid;
    grid-gap: spacer(4) var(--grid-gutter);

    @media #{md("xs")} {
        grid-template-columns: grid-space(1/2) grid-space(1/2);
    }

    @media #{md("md")} {
        grid-template-columns: grid-space(1/4) grid-space(1/4) grid-space(1/4) grid-space(1/4);
    }
}

.o-services_items {
}

.o-services_title {
    margin-bottom: spacer(2);
    font-weight: 400;
    text-transform: uppercase;
}

.o-services_list {
    @include reset-list;
    display: grid;
}

.o-services_item {

    &:not(:first-child) {
        margin-top: .25em;
    }
}



</style>
