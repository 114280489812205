<template>
    <div
        v-if="data"
        class="b-title | grid"
    >
       <!--  <anim-text
            tag="h2"
            :text="title"
            type="block"
            class="b-title_inner | t-h2"
        /> -->
        <h2
            class="b-title_inner | t-h2"
            v-html="title"
        ></h2>
    </div>
</template>

<script>

// import AnimText from 'objects/AnimText';

export default {
    name: 'BlockTitle',
    components: {
        // AnimText
    },
    props: {
        data: Object,
    },
    computed: {
        title() {
            return this.data.title.replace(/<\/?p[^>]*>/g, '')
        }
    }
};

</script>

<style lang="scss">

.b-title {
    margin-top: spacer(3);
    margin-bottom: spacer(4);
}

.b-title_inner {

    @media #{md("xs", "max")} {
        text-indent: grid-space(1/6, 1);
    }

    @media #{md("xs")} {
        padding-right: grid-space(1/4);
        text-indent: grid-space(1/4);
    }

    @media #{md("md")} {
        padding-right: grid-space(1/3);
    }
}

</style>
