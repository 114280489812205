<template>
    <div
        v-if="data"
        class="b-grid"
    >
        <div
            v-for="item in data.children"
            :key="`block-grid-item-${item.id}`"
            class="b-grid_item"
            :class="[
                `-${item.display}`,
                { '-no-margin' : item.display === 'fullscreen' && !item.marginBottom}
            ]"
        >
            <asset
                :asset="item[item.display === 'half' ? 'assetHalf' : 'assetFull']"
                :vimeoID="item.vimeoID"
                :videoControls="item.videoControls"
                :videoLoop="item.videoLoop"
            />
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset'

export default {
    name: 'BlockGrid',
    components: {
        Asset
    },
    props: {
        data: Object,
    },
};

</script>

<style lang="scss">

.b-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--gap);
    margin-bottom: calc(-1 * var(--grid-padding));
}

.b-grid_item {
    width: 100%;
    margin-bottom: var(--gap);

    &.-no-margin {
        margin-bottom: 0;
    }

    &.-large {
        width: calc(100% - 2 * var(--gap));
        margin-right: auto;
        margin-left: auto;
    }

    &:not(.-fullscreen) {
        border-radius: var(--border-radius, 0);
        overflow: hidden;
    }

    @media #{md("xs", "max")} {

        &.-half {
            width: calc(100% - 2 * var(--gap));
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media #{md("xs")} {

        &.-half {
            width: calc(50% - 1.5 * var(--gap));
            margin-left: var(--gap);
        }

        // &.-half {
        //     padding-right: 0;
        //     padding-left: 0;
        //     width: calc(50% - 1.25 * var(--gap));
        //     margin-left: var(--gap);
        //     margin-right: auto;
        //     // width: 50%;
        // }
    }
}

</style>
