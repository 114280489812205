<template>
    <div
        class="o-loader"
        :class="{ '-firstload' : firstload }"
    >
        <span class="o-loader_bg"></span>
        <div
            v-if="firstload"
            ref="lottie"
            class="o-loader_lottie"
        ></div>
        <span
            v-else
            ref="logo"
            class="o-loader_logo"
        >
            <icon
                icon="logo"
                title="Study Hall Creative"
            />
        </span>
    </div>
</template>


<script>

import Icon from 'objects/Icon';

import lottie from 'lottie-web'
import { mapGetters } from 'vuex'

const $html = document.documentElement

export default {
    name: 'Loader',
    components: {
        Icon,
    },
    data: () => ({
        firstload: true,
    }),
    computed: {
        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),
    },
    created() {
        const rootStyles = window.getComputedStyle($html)
        this.durationIn = Number(1000 * rootStyles.getPropertyValue('--loader-duration-in').slice(0, -1))
        this.durationOut = Number(1000 * rootStyles.getPropertyValue('--loader-duration-out').slice(0, -1))
    },
    mounted() {

        this.$store.dispatch('loader/startLoad', null, {root: true});
        this.lottie = lottie.loadAnimation({
            container: this.$refs.lottie,
            path: '/static/animations/loader.json',
            renderer: 'canvas',
            loop: false,
            autoplay: true,
        })

        this.lottie.onComplete = () => {
            this.$store.dispatch('loader/endLoad', null, {root: true})
        }
    },
    watch: {
        isLoading(loading) {

            // Minimum loading time
            if(loading) {
                $html.classList.add('is-loading')

                this.$store.dispatch('global/updateReadyState', false, {root: true})

                this.$store.dispatch('loader/startLoad', null, {root: true});
                setTimeout(() => {
                    this.$store.dispatch('loader/endLoad', null, {root: true})
                }, this.durationIn);

            } else {

                $html.classList.remove('is-loading')

                setTimeout(() => {
                    this.$store.dispatch('global/updateReadyState', true, {root: true})

                    if(this.firstload) {
                        this.firstload = false
                    }

                    // Scroll to top
                    window.scrollTo(0, 0)

                }, this.durationOut);

            }
        }
    }
}

</script>

<style lang="scss">

.o-loader {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    html.is-ready & {
        left: 200vw;
        transition: left 0s var(--loader-duration-out);
    }
}

.o-loader_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-dark;
    will-change: transform;

    html.is-loading & {
        transform-origin: 50% 0;
        transform: scale(1);
        transition: transform var(--loader-duration-in) var(--loader-ease-in);
    }

    html.is-ready & {
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: transform var(--loader-duration-out) var(--loader-ease-out);
    }
}

.o-loader_lottie {
    width: 50vmax;
    opacity: 0;
    transform: translate(0, 2em);
    transition: opacity var(--loader-duration-out) var(--loader-ease-out), transform var(--loader-duration-out) var(--loader-ease-out);

    html.is-loading & {
        opacity: 1;
        transform: translate(0);
    }
}

.o-loader_logo {
    --logo-bg       : #{$color-primary};
    --logo-letters  : #{$color-dark};
    --logo-smiley   : transparent;

    display: inline-block;
    pointer-events: none;
    opacity: 0;
    transform: scale(0);
    transition: opacity var(--loader-duration-out) var(--loader-ease-out), transform var(--loader-duration-out) var(--loader-ease-out);

    .o-icon {
        animation: anim-loader-logo 4s infinite linear;
    }

    .svg-logo {
        --icon-width: 10vmax;
    }

    html.is-loading & {
        opacity: 1;
        transform: scale(1);
        transition: transform var(--loader-duration-in) var(--loader-ease-in);
    }

    html.is-ready & {

        .o-icon {
            animation-play-state: paused;
        }
    }

    @media #{md("xl")} {

        .svg-logo {
            --icon-width: 7vmax;
        }
    }
}

/*----------  Logo animation  ----------*/

@keyframes anim-loader-logo {
    0%      { transform: rotate(0) }
    100%    { transform: rotate(1turn) }
}


</style>
