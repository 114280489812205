<template>
    <footer
        v-if="hasData"
        class="l-footer"
        v-color.light="'var(--theme-color-dark)'"
    >
        <div class="l-footer_inner | grid">

            <div class="l-footer_col">
                <anim-text
                    tag="h3"
                    class="l-footer_headline | t-h4"
                >
                    {{ footer.headline }}
                </anim-text>

                <ul
                    v-if="footer.contact.length > 0"
                    class="l-footer_contact"
                >
                    <li
                        v-for="contact in footer.contact"
                        :key="`Contact ${contact.label}`"
                    >
                        <anim-text
                            tag="a"
                            type="words"
                            :text="contact.label"
                            :href="contact.hyperlink"
                            target="_blank"
                            rel="noopener"
                            class="t-h1 t-link"
                            v-cursor.click
                        />
                    </li>
                </ul>
            </div>

            <div class="l-footer_col -hours">

                <analog-clock
                    v-if="time !== null"
                    class="l-footer_clock"
                    :time="time"
                    :invert="studioIsOpen"
                />

                <div v-if="footer.hours" class="l-footers_hours">
                    <span class="t-h4">
                        HOURS: {{ footer.hours.startLabel }} – {{ footer.hours.endLabel }}
                    </span>
                    <span class="t-h4 -lower">
                        <i v-if="studioIsOpen">Class is in session</i>
                        <i v-else>School is out</i>
                    </span>
                    <icon icon="arrow-down" />
                    <span class="t-h4 -lower">
                        <icon icon="flag-usa" />
                        OAKLAND, CA <br>
                        Pacific Standard Time
                        <!-- {{ time }} -->
                    </span>
                </div>
            </div>

            <div class="l-footer_col -address">
                <icon icon="globe" />
                <anim-text
                    tag="a"
                    :text="footer.address.label"
                    :href="footer.address.gmap"
                    type="words"
                    target="_blank"
                    rel="noopener"
                    class="l-footer_address | t-h1 t-link"
                    v-cursor.click
                />
            </div>


            <span
                v-if="footer.hours"
                class="l-footer_hours"
            ></span>

        </div>

        <div
            ref="bottom"
            class="l-footer_bottom | grid"
        >
            <i
                ref="bottomLogo"
                class="l-footer_logo"
            >
                <svg
                    ref="bottomSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 445 92"
                    aria-labelledby="Study Hall Creative"
                >
                    <path d="M0.03,57.39V46.18h11.77v8.31c-0.04,0.39,0.01,0.78,0.14,1.15c0.13,0.37,0.34,0.71,0.62,0.98c0.28,0.28,0.61,0.49,0.98,0.62c0.37,0.13,0.76,0.18,1.15,0.14h22.82c0.39,0.04,0.78-0.01,1.15-0.14c0.37-0.13,0.71-0.34,0.98-0.62c0.28-0.28,0.49-0.61,0.62-0.98c0.13-0.37,0.18-0.76,0.14-1.15v-7.57c0.04-0.73-0.16-1.45-0.58-2.05c-0.42-0.6-1.03-1.03-1.74-1.24L0,30.11V12.54L11.4,1.13h29.41l11.4,11.41v11.21H40.46v-8.31c0.04-0.39-0.01-0.78-0.14-1.15c-0.13-0.37-0.34-0.71-0.62-0.98c-0.28-0.28-0.61-0.49-0.98-0.62c-0.37-0.13-0.76-0.18-1.15-0.14H14.72c-0.39-0.04-0.78,0.01-1.15,0.14c-0.37,0.13-0.71,0.34-0.98,0.62c-0.28,0.28-0.49,0.61-0.62,0.98c-0.13,0.37-0.18,0.76-0.14,1.15v5.41c-0.04,0.73,0.16,1.45,0.59,2.05c0.42,0.6,1.03,1.03,1.73,1.24l38.09,13.53v19.72L40.83,68.8H11.41L0.03,57.39z"/>
                    <path d="M91.06,29.75H79.3v24.75c-0.05,0.39-0.01,0.79,0.12,1.16c0.13,0.37,0.34,0.71,0.62,0.99c0.28,0.28,0.62,0.49,0.99,0.62c0.37,0.13,0.77,0.17,1.16,0.12h8.89V68.8H78.91l-11.4-11.41V29.75H56.1V18.34h11.4V1.13h11.77v17.21h11.77L91.06,29.75z"/>
                    <path d="M132.72,18.34h11.77v50.43h-11.77v-8.48l-8.5,8.51h-14.53l-11.4-11.41V18.34h11.77v36.14c-0.04,0.39,0.01,0.78,0.14,1.15c0.13,0.37,0.34,0.71,0.62,0.98c0.28,0.28,0.61,0.49,0.98,0.62c0.37,0.13,0.76,0.18,1.15,0.14h16.81c0.39,0.04,0.78-0.01,1.15-0.14c0.37-0.13,0.71-0.34,0.98-0.62c0.28-0.28,0.49-0.61,0.62-0.98c0.13-0.37,0.18-0.76,0.14-1.15L132.72,18.34z"/>
                    <path d="M188.09,1.13h11.77V68.8h-11.77v-8.51l-8.5,8.51h-15.46l-11.4-11.41V29.75l11.4-11.41h15.46l8.5,8.51V1.13zM185.2,57.39c0.39,0.04,0.78-0.01,1.15-0.14c0.37-0.13,0.71-0.34,0.98-0.62c0.28-0.28,0.49-0.61,0.62-0.98c0.13-0.37,0.18-0.76,0.14-1.15V32.64c0.04-0.39-0.01-0.79-0.14-1.16c-0.13-0.37-0.34-0.71-0.62-0.99c-0.28-0.28-0.61-0.49-0.98-0.62c-0.37-0.13-0.77-0.18-1.16-0.14h-17.78c-0.39-0.04-0.78,0.01-1.15,0.14c-0.37,0.13-0.71,0.34-0.98,0.62c-0.28,0.28-0.49,0.61-0.62,0.98c-0.13,0.37-0.18,0.76-0.14,1.15v21.85c-0.04,0.39,0.01,0.78,0.14,1.15c0.13,0.37,0.34,0.71,0.62,0.98c0.28,0.28,0.61,0.49,0.98,0.62c0.37,0.13,0.76,0.18,1.15,0.14H185.2z"/>
                    <path d="M242.51,18.34h11.77v62.25L242.87,92H218.5l-11.33-11.41v-5.71h11.77v5.71h20.68c0.39,0.04,0.78-0.01,1.15-0.14c0.37-0.13,0.71-0.34,0.98-0.62c0.28-0.28,0.49-0.61,0.62-0.98c0.13-0.37,0.18-0.76,0.14-1.15v-17.4l-8.5,8.51h-14.5l-11.4-11.41V18.34h11.77v36.14c-0.04,0.39,0.01,0.78,0.14,1.15c0.13,0.37,0.34,0.71,0.62,0.98c0.28,0.28,0.61,0.49,0.98,0.62c0.37,0.13,0.76,0.18,1.15,0.14h16.81c0.39,0.04,0.78-0.01,1.15-0.14c0.37-0.13,0.71-0.34,0.98-0.62c0.28-0.28,0.49-0.61,0.62-0.98c0.13-0.37,0.18-0.76,0.14-1.15L242.51,18.34z"/>
                    <path d="M297.3,18.34l11.4,11.41V68.8h-11.77V32.66c0.04-0.39-0.01-0.79-0.14-1.16c-0.13-0.37-0.35-0.71-0.62-0.98c-0.28-0.28-0.62-0.49-0.98-0.62c-0.37-0.13-0.77-0.18-1.16-0.14h-16.81c-0.39-0.04-0.79,0.01-1.16,0.14c-0.37,0.13-0.71,0.34-0.98,0.62c-0.28,0.28-0.49,0.61-0.62,0.98c-0.13,0.37-0.18,0.77-0.14,1.16V68.8h-11.77V1.13h11.77v25.71l8.47-8.51H297.3z"/>
                    <path d="M352.29,18.34h11.76v50.43h-11.76v-8.48l-8.51,8.51h-15.46l-11.4-11.41V29.75l11.4-11.41h15.46l8.51,8.51V18.34z M349.39,57.39c0.39,0.04,0.78-0.01,1.15-0.14c0.37-0.13,0.71-0.34,0.98-0.62c0.28-0.28,0.49-0.61,0.62-0.98c0.13-0.37,0.18-0.76,0.14-1.15V32.64c0.04-0.39-0.01-0.79-0.14-1.16c-0.13-0.37-0.34-0.71-0.62-0.98c-0.28-0.28-0.61-0.49-0.98-0.62c-0.37-0.13-0.77-0.18-1.16-0.14h-17.78c-0.39-0.04-0.78,0.01-1.15,0.14c-0.37,0.13-0.71,0.34-0.98,0.62c-0.28,0.28-0.49,0.61-0.62,0.98c-0.13,0.37-0.18,0.76-0.14,1.15v21.85c-0.04,0.39,0.01,0.78,0.14,1.15c0.13,0.37,0.35,0.71,0.62,0.98c0.28,0.28,0.61,0.49,0.98,0.62c0.37,0.13,0.76,0.18,1.15,0.14H349.39z"/>
                    <path d="M383.7,68.8l-11.4-11.41V1.13h11.77v53.36c-0.05,0.39-0.01,0.79,0.12,1.16c0.13,0.37,0.34,0.71,0.62,0.99c0.28,0.28,0.62,0.49,0.99,0.62c0.37,0.13,0.77,0.17,1.16,0.12h8.89V68.8H383.7z"/>
                    <path d="M411.63,68.8l-11.4-11.41V1.13h11.77v53.36c-0.05,0.39-0.01,0.79,0.12,1.16c0.13,0.37,0.34,0.71,0.62,0.99c0.28,0.28,0.62,0.49,0.99,0.62c0.37,0.13,0.77,0.17,1.16,0.12h8.89V68.8H411.63z"/>
                    <path d="M445,11.77c0.01,1.55-0.29,3.08-0.88,4.51c-0.59,1.43-1.45,2.73-2.55,3.82c-1.09,1.09-2.39,1.96-3.82,2.55c-1.43,0.59-2.96,0.89-4.51,0.88c-3.12,0-6.11-1.24-8.32-3.45c-2.21-2.21-3.45-5.2-3.45-8.32s1.24-6.11,3.45-8.32c2.21-2.21,5.2-3.45,8.32-3.45c1.55-0.01,3.08,0.29,4.51,0.88c1.43,0.59,2.73,1.46,3.82,2.55c1.09,1.09,1.96,2.39,2.55,3.82C444.71,8.69,445.01,10.22,445,11.77z M423.99,11.77c-0.01,1.21,0.23,2.4,0.68,3.52c0.46,1.12,1.13,2.13,1.99,2.99c0.86,0.85,1.87,1.53,2.99,1.99c1.12,0.46,2.31,0.69,3.52,0.68c1.21,0.01,2.41-0.22,3.52-0.68c1.12-0.46,2.14-1.13,2.99-1.99c0.85-0.85,1.53-1.87,1.99-2.99c0.46-1.12,0.69-2.32,0.68-3.52c0.01-1.21-0.22-2.41-0.68-3.53s-1.14-2.14-1.99-2.99c-0.86-0.85-1.87-1.53-2.99-1.99c-1.12-0.46-2.32-0.69-3.53-0.68c-1.21-0.01-2.41,0.22-3.52,0.68c-1.12,0.46-2.13,1.14-2.99,1.99c-0.85,0.85-1.53,1.87-1.99,2.99C424.21,9.36,423.98,10.56,423.99,11.77z M439.65,11.77h-2.61c0,1.02-0.41,2.01-1.14,2.73c-0.72,0.72-1.7,1.13-2.73,1.13c-1.02,0-2.01-0.41-2.73-1.13c-0.73-0.72-1.13-1.71-1.14-2.73h-2.6c0,1.72,0.68,3.36,1.9,4.58c1.21,1.21,2.86,1.9,4.57,1.9c0.85,0,1.7-0.17,2.48-0.49c0.79-0.32,1.5-0.8,2.1-1.4c0.6-0.6,1.08-1.31,1.41-2.1C439.49,13.46,439.66,12.62,439.65,11.77L439.65,11.77z M431.91,10.7V6.35h-2.42v4.35H431.91z M436.96,10.7V6.35h-2.52v4.35H436.96z"/>
                </svg>
            </i>
            <anim-text
                tag="small"
                :text="`&copy; Study Hall ${currentYear}. All rights reserved, man.`"
                :reveal="false"
                :visible="showCopy"
                type="words"
                class="l-footer_copy"
            />
        </div>
    </footer>
</template>

<script>

import AnalogClock from 'objects/AnalogClock';
import AnimText from 'objects/AnimText';
import Icon from 'objects/Icon';

import { mapState } from 'vuex'
import { gsap } from 'src/gsap'

export default {
    name: 'AppFooter',
    components: {
        AnalogClock,
        AnimText,
        Icon,
    },
    data: () => ({
        // time: '00:00:00',
        weekDay: null,
        time: null,
        showCopy: false,
    }),
    methods: {
        clock() {

            const timestamp = new Date()

            // Define time
            const timeFormat = new Intl.DateTimeFormat('en-US', {
                timeZone: this.timeZone,
                hour: 'numeric',
                minute: '2-digit',
                second: '2-digit',
                weekday: 'long',
                hourCycle: 'h23',
            })

            const date = timeFormat.format(timestamp).split(' ')
            const time = date[1].split(':')

            this.weekend = ['Saturday', 'Sunday'].includes(date[0])

            this.time = {
                hours: Number(time[0]),
                minutes: Number(time[1]),
                seconds: Number(time[2]),
            }

            setTimeout(() => {
                this.clock()
            }, 1000);
        },
        setBottomAnimation() {

            const theme = {
                light: true,
                bg: 'var(--theme-color-dark)'
            }

            const $svg = this.$refs.bottomSvg
            const bottomTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: this.$refs.bottom,
                    start: 'bottom bottom',
                    end: 'bottom+=350% bottom',
                    pin: true,
                    scrub: true,
                    onEnter: () => {
                        this.$store.dispatch('global/addTheme', theme, {root: true})
                    },
                    onLeaveBack: () => {
                        this.$store.dispatch('global/removeTheme', theme, {root: true})
                    },
                    onUpdate: ({progress}) => {
                        this.showCopy = progress > .8
                    }
                },
                defaults: {
                    ease: 'power2.out'
                },
            })

            bottomTimeline
                .fromTo(Array.from($svg.children),
                {
                    opacity: 0,
                    y: 92,
                },
                {
                    opacity: 1,
                    y: 0,
                    stagger: {
                        amount: 1
                    },
                }, 0)
        }
    },
    computed: {
        ...mapState({
            footer: state => state.global.footer,
        }),
        hasData() {
            return Object.keys(this.footer).length > 0
        },
        currentYear: () => new Date().getFullYear().toString().substr(2,2),
        studioIsOpen() {
            if(this.time === null || this.weekend) {
                return false
            }

            const hours = this.footer.hours
            return this.time.hours - hours.startHour >= 0 && hours.endHour - this.time.hours > 0
        },
        timeZone() {
            return this.footer.hours.timeZone
        },
    },
    watch: {
        hasData(hasData) {
            if(hasData) {
                this.$nextTick(() => {
                    this.clock()
                    this.setBottomAnimation()
                })
            }
        }
    }
};

</script>

<style lang="scss">

.l-footer {
    // margin-top: spacer(9);
    padding-top: spacer(9);
}

.l-footer_inner {
    display: flex;
    flex-wrap: wrap;
    gap: spacer(4) var(--grid-gutter);

    @media #{md("sm")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.l-footer_col {
    width: 100%;

    &.-hours {
        display: grid;
        grid-gap: spacer(2) var(--grid-gutter);
        grid-template-columns: grid-space(5/12) grid-space(7/12);
        align-items: center;
    }

    &.-address {

        .o-icon {
            display: block;
            margin-bottom: spacer(1);
        }
    }

    @media #{md("xs")} {

        &.-hours {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media #{md("sm")} {
        width: grid-space(1/2);

        &.-address:nth-child(3) {
            width: auto;
            margin-left: grid-space(3/12, 1);
        }
    }
}

.l-footer_clock {

    @media #{md("sm", "max")} {
        width: grid-space(5/6);
    }

    @media #{md("xs", "max")} {
        width: grid-space(4/5);
    }
}

.l-footer_headline {
    margin-bottom: spacer(2);
}

.l-footer_contact {
    counter: footer-contact;
    @include reset-list;

    li {
        counter-increment: footer-contact;

        &:not(:first-child) {
            margin-top: spacer(.5);
        }
    }

    a {
        // display: flex;
        // align-items: flex-start;
        // width: 100%;
        padding-left: 1.5rem;

        &:before {
            content: counters(footer-contact, "", decimal-leading-zero);
            position: absolute;
            top: .5em;
            left: 0;
            font-size: 1rem;
            pointer-events: none;
        }
    }
}

.l-footer_address {
    display: block;
}

.l-footers_hours {
    display: flex;
    flex-direction: column;

    > .o-icon {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.l-footer_bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: spacer(7);
    padding-bottom: var(--grid-gutter);
}

.l-footer_logo {
    display: block;
    width: 100%;

    svg {
        display: block;
        width: 100%;
        fill: $color-primary;
    }

    path {
        display: block;
    }
}

.l-footer_copy {
    --at-duration-in: .5s;
    --at-duration-out: .5s;

    margin-top: spacer(2);
    font-size: .75em;
    // opacity: 0;
    // transform: translate(0, 100%);
    // transition: opacity $speed $easing, transform $speed $easing;

    // &.is-visible {
    //     opacity: 1;
    //     transform: translate(0);
    // }
}

</style>
