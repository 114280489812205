<template>
    <div
        v-if="data"
        class="b-bento | grid"
    >
        <div
            v-for="item in data.children"
            :key="`block-bento-item-${item.id}`"
            class="b-bento_item"
        >
            <asset
                :asset="item.assetHalf"
                :vimeoID="item.vimeoID"
            />
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset';

export default {
    name: 'BlockGrid',
    components: {
        Asset
    },
    props: {
        data: Object,
    },
};

</script>

<style lang="scss">

.b-bento {
    display: grid;
    grid-gap: var(--gap);
    margin-top: var(--gap);
    padding-right: var(--gap);
    padding-left: var(--gap);

    @media #{md("xs")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.b-bento_item {
    width: 100%;
    border-radius: var(--border-radius, 0);
    overflow: hidden;

    @media #{md("xs")} {

        &:nth-child(2) {
            grid-column-start: 2;
            grid-row: 1/3;

            .o-asset {
                @include asset-fill;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

</style>
