<template>
    <component
        :is="tag"
        ref="el"
        class="c-squeeze-box"
        :class="{ 'is-open' : isOpen }"
        :style="`--squeeze-box-container-height: ${height}px`"
    >
        <header
            ref="toggler"
            role="button"
            tabindex="0"
            :aria-expanded="isOpen ? 'true' : 'false'"
            class="c-squeeze-box_toggler | t-h1 -lower"
            @click="toggle"
        >
            <slot name="toggler"></slot>
            <span
                v-if="!!$slots.default"
                class="c-squeeze-box_icon"
            ></span>
        </header>
        <div
            ref="container"
            class="c-squeeze-box_container"
        >
            <div
                ref="content"
                class="c-squeeze-box_content"
            >
                <slot></slot>
            </div>
        </div>
    </component>
</template>

<script>

export default {
    name: 'SqueezeBox',
    props: {
        tag: {
            type: String,
            default: 'article'
        },
    },
    data: () => ({
        isOpen: false,
        styles: undefined,
        closeTimeout: null,
        openTimeout: null,
        height: 0,
    }),
    mounted() {

        // Set sizes
        this.calculateHeight()

        // Default opened
        if(this.isOpen) {
            this.open()
        }

        // Bind keyboard events
        this.$refs.toggler.addEventListener('keydown', this.onKeydown = e => {
            if(e.code === 'Space' || e.code === 'Enter') {
                this.toggle()
            }
        })

        // Add window event
        window.addEventListener('resize', this.onResize = () => this.calculateHeight())
    },
    methods: {

        toggle() {

            if(this.isOpen) {
                this.close()
            } else {
                this.open()
            }
        },

        open() {
            this.isOpen = true

            // Clear close callback
            if (this.closeTimeout) {
                clearTimeout(this.closeTimeout);
            }

            // Set open callback
            this.openTimeout = setTimeout(() => {

            }, this.duration)
        },

        close() {
            this.isOpen = false

            // Clear open callback
            if (this.openTimeout) {
                clearTimeout(this.openTimeout);
            }

            // Set close callback
            this.closeTimeout = setTimeout(() => {

            }, this.duration);
        },

        calculateHeight() {
            // Set content height
            this.height = this.$refs.content.offsetHeight

            // // Get styles
            // const duration = window.getComputedStyle(this.el).getPropertyValue('--squeeze-box-duration');
            // this.$container.style.setProperty('--squeeze-box-duration', duration);
            // this.duration = 1000 * parseInt(duration, 10);
        }
    },

    beforeUnmount() {

        // Remove event
        this.$refs.toggler.removeEventListener('keydown', this.onKeydown)
        window.removeEventListener('resize', this.onResize)
    }
};

</script>

<style lang="scss">

/*----------  Mixin  ----------*/
@mixin squeeze-box-disable {

    .c-squeeze-box_container {
        height: auto;
        animation: none !important;
    }

    .c-squeeze-box_content {
        opacity: 1;
        transition: none !important;
    }
}

/*----------  Styles  ----------*/

.c-squeeze-box {
    --squeeze-box-container-height    : 0;
    --squeeze-box-easing              : #{$in-out-expo};
    --squeeze-box-duration            : 1s; // Must be in seconds
}

.c-squeeze-box_toggler {
    cursor: pointer;
    padding-right: 1em;
}

.c-squeeze-box_icon {
    position: absolute;
    right: 0;
    bottom: .2em;
    display: inline-block;
    width: .6em;
    height: .6em;
    transition: transform $speed $easing;

    &:after,
    &:before {
        content: "";
        position: absolute;
        background-color: currentColor;
    }

    &:before {
        top: 0;
        left: calc(50% - 2px/2);
        width: 2px;
        height: 100%;
    }

    &:after {
        left: 0;
        top: calc(50% - 2px/2);
        width: 100%;
        height: 2px;
        transition: inherit;
    }

    .c-squeeze-box.is-open & {
        transform: rotate(90deg);

        &:after {
            transform: rotate(90deg);
        }
    }
}

.c-squeeze-box_container {
    height: 0;
    overflow: hidden;
    animation: anim-squeeze-box-close var(--squeeze-box-duration) var(--squeeze-box-easing) forwards;

    .c-squeeze-box.is-open > & {
        animation: anim-squeeze-box-open var(--squeeze-box-duration) var(--squeeze-box-easing) forwards;
    }
}

.c-squeeze-box_content {
    padding-top: spacer(2);
    opacity: 0;
    transition: opacity calc(0.5 * var(--squeeze-box-duration)) ease-out;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    .c-squeeze-box.is-open > .c-squeeze-box_container & {
        opacity: 1;
        transition: opacity calc(0.5 * var(--squeeze-box-duration)) ease-out calc(0.5 * var(--squeeze-box-duration));
    }
}


/*----------  Animations  ----------*/

@keyframes anim-squeeze-box-open {
    0% {
        height: 0;
    }
    99.99% {
        height: var(--squeeze-box-container-height);
    }
    100% {
        height: auto;
    }
}

@keyframes anim-squeeze-box-close {
    0% {
        height: var(--squeeze-box-container-height);
    }
    100% {
        height: 0;
    }
}

</style>
