<template>
    <grid-debugger />

    <div
        :class="className"
        :style="styles"
        ref="wrapper"
    >

        <loader />

        <app-header :scrollVelocity="scrollVelocity" />

        <main
            id="main"
            ref="main"
            class="l-main"
        >
            <router-view
                v-if="hasCurrentPage"
                :page="currentPage"
            />
        </main>

        <app-footer />

        <app-cursor />
    </div>
</template>

<script>

import AppHeader from 'layout/AppHeader';
import AppFooter from 'layout/AppFooter';
import GridDebugger from 'layout/GridDebugger';

import AppCursor from 'objects/AppCursor';
import Loader from 'objects/Loader';

import { mapState, mapGetters } from 'vuex'

import { Power2, ScrollTrigger, gsap } from 'src/gsap'
import Lenis from '@studio-freight/lenis'

const $html = document.documentElement

export default {
    name: 'AppBase',
    components: {
        AppHeader,
        AppFooter,
        GridDebugger,
        AppCursor,
        Loader,
    },
    data: () => ({
        navIsOpen: false,
        scrollVelocity: 0,
    }),
    mounted() {
        this.lenis = new Lenis({
            duration: 1.2,
            ease: Power2.easeOut
        })

        gsap.ticker.add(t => {
            this.lenis?.raf(t * 1000)
        })

        this.lenis?.on('scroll', (e) => {
            this.scrollVelocity = e.velocity
            ScrollTrigger.update()
        })

        // Watch body height change
        let timeout
        const resizeObserver = new ResizeObserver(() => {

            if(timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
                ScrollTrigger.refresh()
            }, 200)
        })

        resizeObserver.observe(this.$refs.main)
    },
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage,
            currentPageName: state => state.global.currentPageName,
            isReady: state => state.global.isReady
        }),
        ...mapGetters({
            hasCurrentPage: 'global/hasCurrentPage',
            currentTheme: 'global/currentTheme',
        }),
        className() {
            let classname = 'l-wrapper'

            if (this.currentPageName) {
                classname += ` p-${this.currentPageName}`
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += ' nav-is-open'
            }

            return classname
        },
        styles() {
            let styles

            if(this.currentTheme) {
                styles = `--root-bg: ${this.currentTheme.bg};`
                styles += this.currentTheme.light ? ' --root-color: var(--theme-color-light);' : ' --root-color: var(--theme-color-dark);'
            } else {
                styles = undefined
            }

            return styles
        }
    },
    methods: {
        updateMeta() {
            const title = this.currentPage.seo ? this.currentPage.seo.title : this.currentPage.title
            document.title = title
        },
    },
    watch: {
        hasCurrentPage(hasPage) {
            if(hasPage) {
                this.updateMeta()
            }
        },
        isReady(ready) {
            if(ready) {
                $html.classList.add('is-ready')
                this.$store.dispatch('cursor/resetState', false, {root: true})

                ScrollTrigger.refresh()
            } else {
                $html.classList.remove('is-ready')
                this.$store.dispatch('cursor/addState', 'loading', {root: true})
            }
        },
    }
}

</script>

<style lang="scss">

.l-wrapper {
    color: var(--root-color, #{$color-dark});
    background-color: var(--root-bg, #{$color-light});

    html.is-ready & {
        transition: color $speed $easing, background-color $speed $easing;
    }
}


.l-main {
    min-height: vh(100);
    opacity: 0;
    transform: translate(0, vh(-20));
    transition: opacity var(--loader-duration-out) var(--loader-ease-out), transform var(--loader-duration-out) var(--loader-ease-out);

    html.is-loading & {
        transform: translate(0, vh(20));
        transition: opacity var(--loader-duration-in) var(--loader-ease-in), transform var(--loader-duration-in) var(--loader-ease-in);
    }

    html.is-ready & {
        opacity: 1;
        transform: translate(0);
    }
}

</style>
