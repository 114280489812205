<template>
    <section
        v-if="blocks.length > 0"
        class="b"
        :class="{ '-radius': radius }"
    >
        <!-- Defined block -->
        <component
            v-for="(block, i) in blocks"
            :key="`block-${i}`"
            :is="block.typeHandle"
            :data="block"
            class="b_item"
        />
    </section>
</template>

<script>

import BlockBento from 'builder/BlockBento'
import BlockContent from 'builder/BlockContent'
import BlockGrid from 'builder/BlockGrid'
import BlockTheater from 'builder/BlockTheater'
import BlockTitle from 'builder/BlockTitle'

export default {
    name: 'Builder',
    props: {
        blocks: {
            type: Array,
            default: () => [],
        },
        radius: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BlockBento,
        BlockContent,
        BlockGrid,
        BlockTheater,
        BlockTitle,
    },
};

</script>

<style lang="scss">

.b {
    --gap: #{spacer(2)};

    &.-radius {
        --border-radius: calc(0.75 * var(--grid-gutter));
    }
}

.b_item {}

</style>
