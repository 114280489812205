import { createApp } from 'vue';
import App from './App.vue';
import router from './router'
import store from 'src/store'
import color from 'src/directives/color'
import cursor from 'src/directives/cursor'
import { resizeEnd } from './utils'

resizeEnd()

const app = createApp(App)
app.use(store)
app.use(router)
app.directive('color', color)
app.directive('cursor', cursor)

// Initial load
store.dispatch('global/initLoad').then(app.mount('#app'))


// Credits log
console.log(' ');
console.log('%c Made by Lucas Vallenet 💥','background: #171719; color: #fff; font-weight: 500; padding: 5px;');
console.log('%c https://lucasvallenet.com', 'background: #171719; color: #fff; font-weight: 500; padding: 5px;');
console.log(' ');
console.log('🔧 GSAP  → https://greensock.com');
console.log('🔧 Vue   → https://vuejs.org');
console.log('🔧 Craft → https://craftcms.com');
console.log(' ');
