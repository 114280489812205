/*
 * Craft asset transform w/ srcset
 *
 */

const TRANSFORM_DEFAULT = {
    mode: 'crop',
    position: 'center-center',
    quality: 95,
    srcset: false,
}

const transforms = [
    {
        name: 'fullscreen',
        width: 1600,
    },
    {
        name: 'halfscreen',
        width: 800,
    },
]

export const getAsset = (field = 'asset', format = false) => {

    let transform = format && typeof format === 'string' ? transforms.find(t => t.name == format) : false

    const data = `
        url
        title
        width
        height
        mimeType
        ...on uploads_Asset {
            forceOriginal
        }
    `

    if (transform) {
        transform = Object.assign(TRANSFORM_DEFAULT, transform)

        if (transform.srcset) {
            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, quality: ${transform.quality}, position: "${transform.position}", immediately: false) {
                    ${data}
                    mimeType
                    srcset (sizes: ${transform.srcset})
                }
            `
        } else {

            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, quality: ${transform.quality}, position: "${transform.position}", immediately: false) {
                    ${data}
                    mimeType
                }
            `
        }

    } else {

        return `
            ${field} {
                ${data}
            }
        `
    }
}

export default {
    getAsset
}
