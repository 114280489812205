import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'
import parseData from 'src/store/parse'

/*
** Pages
*/

// State
const state = {
    pages: [],
}

// Getters
const getters = {
    getPageById:        state => id => state.pages.find(page => page.id === id),
    getPageByHandle:    state => section => state.pages.find(page => page.sectionHandle === section),
    getPageBySlug:      state => (slug, handle) => state.pages.find(page => page.slug === slug && page.typeHandle === handle),
    getPageIndexBySlug: state => slug => state.pages.findIndex(page => page.slug === slug),
    getPageIndexById:   state => id => state.pages.findIndex(page => page.id === id),
    getPagesCount:      state => state.pages.length,
}

// Actions
const actions = {
    loadSingle(store, handle) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true})

            // Search if page already exists
            let page = store.getters.getPageByHandle(handle)

            // If page already exists, resolve with the page value
            if (typeof page !== 'undefined') {

                resolve(page)

                // End load
                store.dispatch('loader/endLoad', null, {root: true})

            // If page doesn't exist, load page
            } else {

                fetchApi(queries[handle])
                    .then(r => {

                        // Exit on error
                        if (!r || !r.entry) {
                            throw new Error(`Page not found`)

                        } else {

                            // Flatten entry and add other data to object
                            let { entry, ...data} = r // eslint-disable-line
                            data = { ...r.entry, ...data }
                            data = parseData(handle, data)

                            store.commit('addPage', data)
                            resolve(data)
                            store.dispatch('loader/endLoad', null, {root: true})

                        }
                    })
                    .catch(e => {

                        reject({
                            code: 404,
                            message: e
                        })
                        store.dispatch('loader/endLoad', null, {root: true})
                    })

            }

        })
    },
    loadEntry(store, {handle, slug}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true})

            // Search if page already exists
            let page = store.getters.getPageBySlug(slug, handle)

            // If page already exists, resolve with the page value
            if(typeof page !== 'undefined' && page.singleFetch) {
                resolve(page)

                // End load
                store.dispatch('loader/endLoad', null, {root: true})

            // If page doesn't exist, load page
            } else {
                fetchApi(queries[handle](slug))

                    .then(r => {

                        // Exit on error
                        if (!r || !r.entry) {
                            throw new Error(`Entry not found`)

                        } else {

                            // Flatten entry and add other data to object
                            let { entry, ...data} = r // eslint-disable-line
                            data = { ...r.entry, ...data }
                            data.singleFetch = true
                            data = parseData(handle, data)

                            //const index = store.getters.getPageIndexById(data.id)
                            store.commit('addPage', data)
                            resolve(data)
                            store.dispatch('loader/endLoad', null, {root: true})
                        }
                    })
                    .catch(e => {

                        reject({
                            code: 404,
                            message: e
                        })
                        store.dispatch('loader/endLoad', null, {root: true})
                    })

            }

        })
    },
}

// Mutations
const mutations = {
    addPage(state, data) {
        state.pages.push(data)
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
