<template>
    <div
        ref="el"
        class="o-analog-clock"
        :class="{ '-invert' : invert }"
    >
        <div ref="inner" class="o-analog-clock_inner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
                <circle class="o-analog-clock_bg" cx="70" cy="70" r="70"/>
                <g class="o-analog-clock_outline">
                    <path d="M70,1.5c37.77,0,68.5,30.73,68.5,68.5s-30.73,68.5-68.5,68.5S1.5,107.77,1.5,70S32.23,1.5,70,1.5 M70,0C31.34,0,0,31.34,0,70c0,38.66,31.34,70,70,70s70-31.34,70-70C140,31.34,108.66,0,70,0L70,0z"/>
                </g>
                <g>
                    <rect x="75.68" y="124.58" transform="matrix(0.9945 -0.1046 0.1046 0.9945 -12.9284 8.6521)" width="0.75" height="6.1"/>
                    <rect x="63.57" y="9.32" transform="matrix(0.9945 -0.1044 0.1044 0.9945 -0.9417 6.7433)" width="0.75" height="6.1"/>
                    <rect x="57.58" y="10.27" transform="matrix(0.9782 -0.2079 0.2079 0.9782 -1.5019 12.3389)" width="0.75" height="6.1"/>
                    <rect x="81.67" y="123.64" transform="matrix(0.9781 -0.2079 0.2079 0.9781 -24.5486 19.8295)" width="0.75" height="6.1"/>
                    <rect x="51.72" y="11.84" transform="matrix(0.951 -0.3091 0.3091 0.951 -2.0501 16.8283)" width="0.75" height="6.1"/>
                    <polygon points="88.49,128.13 86.61,122.33 87.32,122.1 89.21,127.9"/>
                    <rect x="93.19" y="119.89" transform="matrix(0.9136 -0.4067 0.4067 0.9136 -41.9104 48.6798)" width="0.75" height="6.1"/>
                    <rect x="46.05" y="14.01" transform="matrix(0.9135 -0.4068 0.4068 0.9135 -2.9252 20.3611)" width="0.75" height="6.1"/>
                    <rect x="35.56" y="20.07" transform="matrix(0.809 -0.5878 0.5878 0.809 -6.7259 25.5427)" width="0.75" height="6.11"/>
                    <rect x="103.68" y="113.83" transform="matrix(0.809 -0.5878 0.5878 0.809 -48.8271 83.49)" width="0.75" height="6.11"/>
                    <rect x="108.4" y="110.01" transform="matrix(0.7432 -0.6691 0.6691 0.7432 -47.7146 101.8198)" width="0.75" height="6.11"/>
                    <rect x="30.85" y="23.88" transform="matrix(0.7432 -0.6691 0.6691 0.7432 -10.0037 27.8112)" width="0.75" height="6.11"/>
                    <rect x="112.69" y="105.72" transform="matrix(0.6691 -0.7432 0.7432 0.6691 -43.4258 120.0181)" width="0.75" height="6.11"/>
                    <rect x="26.56" y="28.17" transform="matrix(0.669 -0.7432 0.7432 0.669 -14.2927 30.3557)" width="0.75" height="6.1"/>
                    <rect x="22.75" y="32.89" transform="matrix(0.5877 -0.8091 0.8091 0.5877 -19.546 33.5259)" width="0.75" height="6.11"/>
                    <rect x="116.5" y="101.01" transform="matrix(0.5878 -0.809 0.809 0.5878 -36.0053 137.458)" width="0.75" height="6.11"/>
                    <rect x="122.56" y="90.52" transform="matrix(0.4066 -0.9136 0.9136 0.4066 -12.5362 167.838)" width="0.75" height="6.1"/>
                    <rect x="16.69" y="43.38" transform="matrix(0.4067 -0.9136 0.9136 0.4067 -32.2947 43.1336)" width="0.75" height="6.1"/>
                    <rect x="124.74" y="84.86" transform="matrix(0.3091 -0.951 0.951 0.3091 2.8424 179.7287)" width="0.75" height="6.1"/>
                    <polygon points="17.67,53.39 11.87,51.51 12.1,50.79 17.9,52.68"/>
                    <rect x="12.94" y="54.9" transform="matrix(0.2079 -0.9781 0.9781 0.2079 -46.1384 58.9248)" width="0.75" height="6.1"/>
                    <rect x="126.31" y="79" transform="matrix(0.2079 -0.9782 0.9782 0.2079 20.0914 188.9081)" width="0.75" height="6.1"/>
                    <rect x="11.99" y="60.89" transform="matrix(0.1046 -0.9945 0.9945 0.1046 -52.5186 69.556)" width="0.75" height="6.1"/>
                    <rect x="127.26" y="73.01" transform="matrix(0.1044 -0.9945 0.9945 0.1044 38.6664 195.053)" width="0.75" height="6.1"/>
                    <rect x="124.58" y="63.57" transform="matrix(0.9946 -0.1041 0.1041 0.9946 -5.9652 13.6398)" width="6.1" height="0.75"/>
                    <rect x="9.32" y="75.68" transform="matrix(0.9946 -0.1041 0.1041 0.9946 -7.8536 1.7015)" width="6.1" height="0.75"/>
                    <rect x="10.27" y="81.67" transform="matrix(0.9781 -0.2081 0.2081 0.9781 -16.7835 4.5672)" width="6.1" height="0.75"/>
                    <rect x="123.64" y="57.58" transform="matrix(0.9781 -0.2081 0.2081 0.9781 -9.2864 27.6329)" width="6.1" height="0.75"/>
                    <polygon points="122.33,53.39 122.1,52.68 127.9,50.79 128.13,51.51"/>
                    <rect x="11.84" y="87.53" transform="matrix(0.9507 -0.31 0.31 0.9507 -26.5157 8.9436)" width="6.1" height="0.75"/>
                    <rect x="119.89" y="46.06" transform="matrix(0.9138 -0.4061 0.4061 0.9138 -8.2611 53.932)" width="6.1" height="0.75"/>
                    <rect x="14.01" y="93.19" transform="matrix(0.9138 -0.4061 0.4061 0.9138 -36.5318 14.9936)" width="6.1" height="0.75"/>
                    <rect x="113.83" y="35.56" transform="matrix(0.809 -0.5879 0.5879 0.809 1.2005 75.5748)" width="6.11" height="0.75"/>
                    <rect x="20.07" y="103.68" transform="matrix(0.809 -0.5879 0.5879 0.809 -56.7557 33.4712)" width="6.11" height="0.75"/>
                    <rect x="110.01" y="30.85" transform="matrix(0.7426 -0.6697 0.6697 0.7426 8.1861 83.7529)" width="6.1" height="0.75"/>
                    <rect x="23.88" y="108.4" transform="matrix(0.7426 -0.6697 0.6697 0.7426 -65.9125 46.0327)" width="6.11" height="0.75"/>
                    <rect x="105.72" y="26.56" transform="matrix(0.6697 -0.7426 0.7426 0.6697 15.9257 89.6785)" width="6.1" height="0.75"/>
                    <rect x="28.17" y="112.69" transform="matrix(0.6697 -0.7426 0.7426 0.6697 -73.6519 60.5359)" width="6.11" height="0.75"/>
                    <rect x="32.89" y="116.5" transform="matrix(0.5879 -0.809 0.809 0.5879 -79.7386 77.2445)" width="6.11" height="0.75"/>
                    <rect x="101.01" y="22.75" transform="matrix(0.5879 -0.809 0.809 0.5879 24.1829 93.7093)" width="6.11" height="0.75"/>
                    <rect x="90.52" y="16.69" transform="matrix(0.4061 -0.9138 0.9138 0.4061 39.9771 95.6372)" width="6.1" height="0.75"/>
                    <rect x="43.38" y="122.56" transform="matrix(0.4061 -0.9138 0.9138 0.4061 -84.7706 115.4371)" width="6.1" height="0.75"/>
                    <rect x="49.04" y="124.74" transform="matrix(0.31 -0.9507 0.9507 0.31 -83.0073 135.8592)" width="6.1" height="0.75"/>
                    <polygon points="87.32,17.9 86.61,17.67 88.49,11.87 89.21,12.1"/>
                    <rect x="79" y="12.94" transform="matrix(0.2081 -0.9781 0.9781 0.2081 51.9506 90.7965)" width="6.1" height="0.75"/>
                    <rect x="54.9" y="126.31" transform="matrix(0.2081 -0.9781 0.9781 0.2081 -78.0206 157.0038)" width="6.1" height="0.75"/>
                    <rect x="73.01" y="11.99" transform="matrix(0.1041 -0.9946 0.9946 0.1041 55.8371 86.7224)" width="6.1" height="0.75"/>
                    <rect x="60.89" y="127.26" transform="matrix(0.1041 -0.9946 0.9946 0.1041 -69.6559 177.936)" width="6.1" height="0.75"/>
                    <rect x="69.63" y="121" width="0.75" height="10"/>
                    <path d="M70,70"/>
                    <rect x="69.63" y="9" width="0.75" height="10"/>
                    <rect x="97.62" y="113.49" transform="matrix(0.866 -0.5 0.5 0.866 -46.1187 64.8767)" width="0.75" height="10.01"/>
                    <path d="M70,70"/>
                    <path d="M70,70"/>
                    <rect x="41.63" y="16.5" transform="matrix(0.866 -0.5 0.5 0.866 -5.1255 23.8826)" width="0.75" height="10.01"/>
                    <path d="M70,70"/>
                    <path d="M70,70"/>
                    <rect x="21.13" y="37" transform="matrix(0.5 -0.866 0.866 0.5 -25.6219 39.6262)" width="0.75" height="10.01"/>
                    <path d="M70,70"/>
                    <rect x="118.12" y="93" transform="matrix(0.5 -0.866 0.866 0.5 -25.6226 151.6166)" width="0.75" height="10.01"/>
                    <path d="M70,70"/>
                    <rect x="9" y="69.62" width="10" height="0.75"/>
                    <rect x="121" y="69.62" width="10" height="0.75"/>
                    <path d="M70,70"/>
                    <rect x="16.5" y="97.62" transform="matrix(0.8663 -0.4995 0.4995 0.8663 -46.0751 23.8426)" width="10.01" height="0.75"/>
                    <path d="M70,70"/>
                    <rect x="113.49" y="41.63" transform="matrix(0.8663 -0.4995 0.4995 0.8663 -5.1387 64.8027)" width="10.01" height="0.75"/>
                    <path d="M70,70"/>
                    <rect x="37" y="118.12" transform="matrix(0.4995 -0.8663 0.8663 0.4995 -81.6318 95.6935)" width="10.01" height="0.75"/>
                    <path d="M70,70"/>
                    <path d="M70,70"/>
                    <path d="M70,70"/>
                    <rect x="93" y="21.13" transform="matrix(0.4995 -0.8663 0.8663 0.4995 30.4182 95.6612)" width="10.01" height="0.75"/>
                </g>
                <g>
                    <circle cx="70" cy="70" r="2"/>
                </g>
                <g>
                    <path d="M38.03,14.79c0.28,0.49,0.09,0.96-0.36,1.22c-0.54,0.31-0.99,0.18-1.23-0.2l0.44-0.25c0.12,0.16,0.31,0.24,0.56,0.1c0.22-0.13,0.29-0.35,0.13-0.61c-0.14-0.25-0.35-0.31-0.58-0.17c-0.13,0.08-0.24,0.21-0.27,0.37L36.3,15.5l-0.44-1.3l1.27-0.74l0.21,0.37l-0.96,0.55l0.18,0.52l0,0.01c0.05-0.16,0.16-0.31,0.37-0.43C37.34,14.25,37.78,14.36,38.03,14.79z"/>
                    <path d="M39.87,13.73c0.28,0.49,0.09,0.96-0.36,1.22c-0.54,0.31-0.99,0.18-1.23-0.2l0.44-0.25c0.11,0.16,0.31,0.24,0.56,0.1c0.22-0.13,0.29-0.35,0.13-0.61c-0.14-0.25-0.35-0.31-0.58-0.17c-0.14,0.08-0.24,0.2-0.27,0.37l-0.43,0.25l-0.44-1.3l1.28-0.74l0.21,0.37l-0.96,0.55l0.18,0.52l0,0.01c0.05-0.16,0.16-0.31,0.37-0.43C39.19,13.18,39.63,13.3,39.87,13.73z"/>
                    <path d="M100.32,126.43c-0.27-0.47-0.21-0.95,0.35-1.26c0.42-0.25,0.86-0.19,1.09,0.21c0.23,0.4,0.04,0.83-0.14,1.21c-0.08,0.17-0.17,0.33-0.17,0.48l0,0.01l0.95-0.55l0.21,0.37l-1.56,0.9c-0.24-0.42-0.13-0.78,0.12-1.33c0.17-0.35,0.27-0.56,0.14-0.79c-0.1-0.17-0.25-0.25-0.46-0.13c-0.25,0.14-0.27,0.35-0.11,0.64L100.32,126.43z"/>
                    <path d="M104.08,125.1c0.28,0.49,0.09,0.96-0.36,1.22c-0.54,0.31-0.99,0.18-1.23-0.2l0.44-0.25c0.12,0.16,0.31,0.24,0.56,0.1c0.22-0.13,0.29-0.35,0.13-0.61c-0.14-0.25-0.35-0.31-0.58-0.17c-0.13,0.08-0.24,0.2-0.27,0.37l-0.43,0.25l-0.44-1.3l1.27-0.74l0.21,0.37l-0.96,0.55l0.18,0.52l0,0.01c0.05-0.16,0.16-0.31,0.37-0.43C103.39,124.56,103.83,124.67,104.08,125.1z"/>
                    <path d="M14.62,38.19c0.49,0.28,0.56,0.78,0.3,1.23c-0.31,0.54-0.77,0.66-1.17,0.44L14,39.43c0.18,0.08,0.39,0.05,0.54-0.2c0.13-0.22,0.07-0.44-0.19-0.59c-0.25-0.14-0.45-0.09-0.59,0.14c-0.08,0.14-0.11,0.3-0.04,0.46l-0.25,0.43l-1.03-0.9l0.74-1.27l0.37,0.21l-0.55,0.96l0.41,0.36l0.01,0c-0.04-0.17-0.02-0.35,0.1-0.55C13.75,38.06,14.19,37.94,14.62,38.19z"/>
                    <path d="M14.42,37.77c-0.62-0.35-0.93-0.91-0.58-1.52c0.35-0.61,0.99-0.61,1.6-0.25c0.61,0.35,0.93,0.9,0.58,1.51C15.67,38.12,15.03,38.12,14.42,37.77z M15.17,36.46c-0.4-0.23-0.78-0.32-0.97,0c-0.19,0.33,0.08,0.61,0.48,0.84c0.4,0.23,0.77,0.32,0.96-0.01C15.84,36.97,15.57,36.69,15.17,36.46z"/>
                    <path d="M124.39,103.73c-0.47-0.27-0.65-0.72-0.33-1.27c0.24-0.42,0.65-0.59,1.05-0.36c0.41,0.23,0.45,0.71,0.48,1.11c0.01,0.19,0.02,0.37,0.09,0.5l0.01,0l0.55-0.95l0.37,0.21l-0.9,1.56c-0.42-0.24-0.5-0.61-0.56-1.22c-0.03-0.38-0.05-0.62-0.27-0.75c-0.17-0.1-0.34-0.1-0.46,0.11c-0.14,0.25-0.06,0.44,0.23,0.61L124.39,103.73z"/>
                    <path d="M125.71,102.12c-0.61-0.36-0.93-0.91-0.58-1.52c0.35-0.61,0.99-0.61,1.6-0.25c0.61,0.35,0.93,0.9,0.58,1.51S126.32,102.48,125.71,102.12z M126.46,100.81c-0.4-0.23-0.78-0.32-0.97,0c-0.19,0.33,0.08,0.61,0.48,0.84c0.4,0.23,0.77,0.32,0.96-0.01C127.13,101.32,126.86,101.04,126.46,100.81z"/>
                    <path d="M6.82,71.31l-0.53,0l0.01,1.12l-0.5,0l-1.4-1.11l0-0.51l1.47-0.01l0-0.35l0.42,0l0,0.35l0.53,0L6.82,71.31z M5.87,72.02l0-0.71l-0.88,0L5.87,72.02z"/>
                    <path d="M5.98,68.35c0.57,0,0.88,0.39,0.88,0.92c0,0.62-0.33,0.95-0.78,0.97l0-0.5c0.19-0.02,0.37-0.15,0.36-0.44c0-0.25-0.16-0.42-0.46-0.42c-0.29,0-0.44,0.15-0.44,0.42c0,0.16,0.06,0.31,0.19,0.42l0,0.49L4.4,69.94l-0.01-1.47l0.42,0l0,1.11l0.54,0.1h0.01c-0.12-0.13-0.19-0.3-0.19-0.53C5.17,68.67,5.49,68.35,5.98,68.35z"/>
                    <path d="M132.96,70.89l2.41-0.01l0,0.51l-1.68,0.01c0.08,0.1,0.11,0.29,0.11,0.51l-0.41,0c-0.01-0.37-0.14-0.59-0.43-0.6L132.96,70.89z"/>
                    <path d="M134.54,68.63c0.57,0,0.88,0.39,0.88,0.92c0,0.62-0.33,0.95-0.78,0.97l0-0.5c0.19-0.02,0.37-0.15,0.36-0.44c0-0.25-0.16-0.42-0.46-0.42c-0.29,0-0.44,0.15-0.44,0.42c0,0.16,0.06,0.31,0.19,0.42l0,0.49l-1.34-0.26l-0.01-1.47l0.42,0l0,1.11l0.54,0.1h0.01c-0.12-0.13-0.19-0.3-0.19-0.53C133.73,68.95,134.05,68.63,134.54,68.63z"/>
                    <path d="M126.45,36.63l-2.09,1.21l-0.26-0.44l1.46-0.84c-0.12-0.04-0.24-0.2-0.35-0.39l0.35-0.2c0.19,0.32,0.42,0.45,0.67,0.31L126.45,36.63z"/>
                    <path d="M125.6,37.58c0.62-0.35,1.25-0.35,1.61,0.26c0.35,0.61,0.03,1.16-0.58,1.51s-1.25,0.36-1.6-0.25C124.67,38.48,124.99,37.93,125.6,37.58z M126.35,38.89c0.4-0.23,0.67-0.51,0.48-0.84c-0.19-0.33-0.57-0.24-0.97-0.01c-0.39,0.23-0.67,0.51-0.47,0.84C125.58,39.21,125.96,39.12,126.35,38.89z"/>
                    <path d="M13.07,102.12l0.46-0.26l-0.56-0.97l0.43-0.25l1.76,0.27l0.26,0.44l-1.27,0.73l0.18,0.3l-0.36,0.21l-0.17-0.3l-0.46,0.26L13.07,102.12z M13.53,101.04l0.36,0.61l0.76-0.44L13.53,101.04z"/>
                    <path d="M14.66,102.47c0.61-0.35,1.25-0.35,1.6,0.26c0.35,0.61,0.03,1.16-0.58,1.51c-0.61,0.35-1.25,0.36-1.6-0.25C13.73,103.38,14.05,102.83,14.66,102.47z M15.41,103.78c0.4-0.23,0.67-0.51,0.48-0.84c-0.19-0.33-0.57-0.24-0.97-0.01c-0.4,0.23-0.67,0.51-0.48,0.84C14.64,104.1,15.02,104.01,15.41,103.78z"/>
                    <path d="M100.59,13.4c0.36-0.62,0.91-0.93,1.52-0.58s0.61,0.99,0.25,1.6c-0.35,0.61-0.9,0.93-1.51,0.58C100.24,14.65,100.24,14.01,100.59,13.4z M101.9,14.16c0.23-0.4,0.32-0.78,0-0.97c-0.33-0.19-0.61,0.08-0.84,0.48c-0.23,0.4-0.32,0.77,0.01,0.96C101.39,14.82,101.67,14.55,101.9,14.16z"/>
                    <path d="M104.03,15.83c-0.28,0.49-0.78,0.56-1.23,0.3c-0.54-0.31-0.65-0.77-0.45-1.17l0.44,0.25c-0.08,0.18-0.05,0.39,0.2,0.54c0.22,0.13,0.44,0.07,0.59-0.19c0.14-0.25,0.09-0.45-0.14-0.59c-0.14-0.08-0.3-0.11-0.46-0.04l-0.43-0.25l0.9-1.03l1.28,0.74l-0.21,0.37l-0.96-0.55l-0.36,0.41l0,0.01c0.17-0.03,0.35-0.02,0.55,0.1C104.16,14.96,104.27,15.4,104.03,15.83z"/>
                    <path d="M36.57,124.27c0.23-0.4,0.69-0.52,1.19-0.22c0.4,0.23,0.62,0.63,0.41,1c-0.13,0.22-0.33,0.29-0.57,0.28l0,0.01c0.22,0.23,0.22,0.48,0.08,0.72c-0.24,0.42-0.68,0.46-1.17,0.18c-0.55-0.32-0.7-0.78-0.42-1.28l0.44,0.25c-0.15,0.26-0.13,0.47,0.17,0.65c0.26,0.15,0.42,0.1,0.53-0.09c0.13-0.23,0.02-0.4-0.21-0.53l-0.14-0.08l0.19-0.32l0.13,0.07c0.19,0.11,0.38,0.11,0.5-0.09c0.08-0.14,0.05-0.3-0.15-0.42c-0.23-0.13-0.42-0.09-0.54,0.12L36.57,124.27z"/>
                    <path d="M39.59,127.01c-0.28,0.49-0.78,0.56-1.23,0.3c-0.54-0.31-0.66-0.77-0.45-1.16l0.44,0.25c-0.08,0.18-0.05,0.39,0.2,0.54c0.22,0.13,0.44,0.07,0.59-0.19c0.14-0.25,0.09-0.45-0.14-0.59c-0.14-0.08-0.3-0.11-0.46-0.04l-0.43-0.25l0.9-1.03l1.27,0.74l-0.21,0.37l-0.96-0.55l-0.36,0.42l0,0.01c0.17-0.04,0.35-0.02,0.55,0.1C39.72,126.14,39.84,126.58,39.59,127.01z"/>
                    <path d="M68.67,5.62c0.13-0.15,0.31-0.25,0.58-0.25c0.49,0,0.82,0.35,0.82,0.81c0,0.51-0.35,0.87-0.92,0.87c-0.76,0-1.01-0.57-1.01-1.24c0-0.84,0.39-1.28,1-1.28c0.46,0,0.79,0.29,0.86,0.66h-0.52c-0.02-0.13-0.11-0.27-0.34-0.27c-0.36,0-0.47,0.3-0.48,0.65L68.67,5.62L68.67,5.62z M69.57,6.21c0-0.26-0.16-0.44-0.43-0.44c-0.29,0-0.46,0.19-0.46,0.45c0,0.26,0.16,0.44,0.45,0.44C69.42,6.66,69.57,6.47,69.57,6.21z"/>
                    <path d="M70.35,5.79c0-0.71,0.32-1.26,1.02-1.26c0.71,0,1.02,0.55,1.02,1.26c0,0.71-0.31,1.26-1.02,1.26C70.66,7.05,70.35,6.5,70.35,5.79z M71.86,5.79c0-0.46-0.11-0.84-0.49-0.84c-0.38,0-0.49,0.38-0.49,0.84c0,0.46,0.11,0.83,0.49,0.83C71.75,6.63,71.86,6.25,71.86,5.79z"/>
                    <path d="M68.17,133.82c0-0.46,0.34-0.79,0.92-0.79c0.46,0,0.86,0.24,0.86,0.66c0,0.26-0.14,0.42-0.35,0.52v0.01c0.3,0.09,0.43,0.3,0.43,0.58c0,0.48-0.36,0.74-0.92,0.74c-0.64,0-1-0.33-1-0.89h0.5c0,0.3,0.13,0.47,0.48,0.47c0.3,0,0.42-0.13,0.42-0.34c0-0.27-0.19-0.35-0.45-0.35h-0.16v-0.37h0.15c0.22,0,0.39-0.1,0.39-0.33c0-0.17-0.1-0.29-0.34-0.29c-0.27,0-0.41,0.14-0.41,0.37H68.17z"/>
                    <path d="M70.29,134.29c0-0.71,0.32-1.26,1.02-1.26c0.71,0,1.02,0.55,1.02,1.26c0,0.71-0.31,1.26-1.02,1.26C70.61,135.55,70.29,135,70.29,134.29z M71.8,134.29c0-0.46-0.11-0.84-0.49-0.84c-0.38,0-0.49,0.38-0.49,0.84c0,0.46,0.11,0.83,0.49,0.83C71.69,135.12,71.8,134.75,71.8,134.29z"/>
                </g>
                <g>
                    <path d="M67.62,25.02V34h-1.17v-6.72c-0.55,0.52-1.07,0.52-1.87,0.52v-0.84c1.57,0,2-0.77,2.13-1.94H67.62z"/>
                    <path d="M69.3,28.16c0-1.86,1.01-3.33,3.24-3.33c1.53,0,2.86,0.97,2.86,2.69c0,3.08-4.38,3.26-4.94,5.41v0.03h4.81V34h-6.23c0-1.62,1.03-2.9,2.68-3.86c1.68-0.97,2.51-1.29,2.51-2.6c0-0.75-0.34-1.66-1.72-1.66c-1.57,0-2.04,1.05-2.04,2.29H69.3z"/>
                    <path d="M70.36,109.34c1.72,0,2.91,1.18,2.91,2.96c0,1.66-1.17,3.08-3.12,3.08c-2.46,0-3.37-1.82-3.37-4.68c0-2.99,1.17-4.67,3.41-4.67c1.68,0,2.72,1.14,2.85,2.46h-1.17c-0.12-0.87-0.66-1.42-1.68-1.42c-1.86,0-2.21,1.77-2.24,3.58v0.03C68.4,109.84,69.31,109.34,70.36,109.34z M72.11,112.39c0-1.29-0.78-2-1.85-2c-1.3,0-2.17,0.82-2.17,2.03c0,1.16,0.77,1.94,2.07,1.94C71.39,114.35,72.11,113.58,72.11,112.39z"/>
                    <path d="M46.67,31.59l4.49,7.78l-1.01,0.58l-3.36-5.82c-0.21,0.72-0.66,0.98-1.36,1.39l-0.42-0.73c1.36-0.79,1.35-1.67,0.88-2.74L46.67,31.59z"/>
                    <path d="M50.57,29.34l4.49,7.78l-1.01,0.58l-3.36-5.82c-0.21,0.72-0.66,0.98-1.36,1.39l-0.42-0.73c1.36-0.79,1.35-1.67,0.88-2.74L50.57,29.34z"/>
                    <path d="M93.87,104.95c0.97,1.68,0.4,3.52-1.17,4.43c-1.78,1.03-3.43,0.45-4.2-0.77l1.01-0.58c0.53,0.7,1.4,1.18,2.62,0.48c1.07-0.62,1.44-1.72,0.72-2.97c-0.68-1.17-1.67-1.26-2.67-0.68c-0.7,0.4-1.18,0.98-1.28,1.71l-1.08,0.62l-1.61-4.66l4.28-2.47l0.52,0.9l-3.46,2l0.9,2.62l0.01,0.02c0.13-0.68,0.56-1.29,1.43-1.79C91.36,102.97,93.03,103.5,93.87,104.95z"/>
                    <path d="M29.69,49.88l7.78,4.49l-0.58,1.01l-5.82-3.36c0.18,0.73-0.08,1.18-0.49,1.88l-0.73-0.42c0.79-1.36,0.34-2.12-0.61-2.82L29.69,49.88z"/>
                    <path d="M34.38,50.76c-2.21-1.27-3.55-3.21-2.32-5.33c1.23-2.13,3.58-1.93,5.78-0.65c2.21,1.27,3.54,3.2,2.31,5.33C38.92,52.22,36.58,52.03,34.38,50.76z M37.25,45.78c-1.66-0.96-3.44-1.32-4.3,0.16c-0.86,1.49,0.35,2.85,2.01,3.8c1.66,0.96,3.43,1.32,4.29-0.17S38.9,46.74,37.25,45.78z"/>
                    <path d="M109.69,91.7l-1.82-1.05l-2.16,3.75l-0.9-0.52l-2.89-6.67l0.58-1.01l5.05,2.92l0.62-1.07l0.9,0.52l-0.62,1.07l1.82,1.05L109.69,91.7z M105.4,92.84l1.57-2.71l-3.65-2.11L105.4,92.84z"/>
                    <path d="M30.87,70.32c0,1.72-1.18,2.91-2.96,2.91c-1.66,0-3.08-1.17-3.08-3.12c0-2.46,1.82-3.37,4.68-3.37c2.99,0,4.67,1.17,4.67,3.41c0,1.68-1.14,2.72-2.46,2.85v-1.17c0.87-0.12,1.42-0.66,1.42-1.68c0-1.86-1.77-2.21-3.58-2.24h-0.03C30.38,68.36,30.87,69.27,30.87,70.32z M27.83,72.06c1.29,0,2-0.78,2-1.85c0-1.3-0.82-2.17-2.03-2.17c-1.16,0-1.94,0.77-1.94,2.07C25.87,71.35,26.64,72.06,27.83,72.06z"/>
                    <path d="M108.91,73.22c-1.65,0-2.87-1.08-2.87-3.3c0-1.53,0.9-2.89,2.48-2.89c0.95,0,1.55,0.47,1.91,1.38h0.03c0.35-1.27,1.17-1.7,2.22-1.7c1.77,0,2.7,1.26,2.7,3.19c0,2.29-1.2,3.56-3.22,3.56v-1.17c1.3,0,2.18-0.6,2.18-2.3c0-1.55-0.58-2.11-1.66-2.11c-1.4,0-1.65,1.03-1.65,1.9v0.96h-1.04v-0.91c0-0.92-0.43-1.62-1.47-1.62c-0.73,0-1.44,0.49-1.44,1.72c0,1.76,0.95,2.13,1.83,2.13V73.22z"/>
                    <path d="M104.72,46.57c1.61-0.93,3.39-0.79,4.5,1.14c0.77,1.33,0.59,2.96-0.9,3.82c-2.67,1.54-5.02-2.16-7.15-1.57l-0.02,0.01l2.4,4.17l-0.9,0.52l-3.11-5.39c1.41-0.81,3.02-0.56,4.68,0.39c1.68,0.96,2.37,1.53,3.5,0.87c0.65-0.38,1.27-1.12,0.58-2.32c-0.79-1.36-1.93-1.24-3-0.62L104.72,46.57z"/>
                    <path d="M34.08,88.79l0.02-0.01c-0.03-0.72,0.18-1.5,1.06-2.01c1.34-0.77,2.73-0.19,3.58,1.29c0.86,1.49,0.67,2.98-0.67,3.75c-0.88,0.51-1.67,0.29-2.27-0.08l-0.02,0.01c0.1,0.92-0.02,1.94-1.1,2.56c-1.57,0.9-3.06,0.1-4.03-1.56s-0.91-3.37,0.66-4.27C32.39,87.85,33.33,88.24,34.08,88.79z M34.13,93.27c0.95-0.55,1.04-1.59,0.37-2.75s-1.62-1.6-2.56-1.06c-0.97,0.56-1.04,1.6-0.38,2.75C32.22,93.38,33.16,93.83,34.13,93.27z M37.49,90.8c0.84-0.49,0.88-1.32,0.36-2.22c-0.52-0.9-1.25-1.29-2.1-0.8c-0.87,0.5-0.88,1.32-0.36,2.22S36.63,91.3,37.49,90.8z"/>
                    <path d="M93.56,31.69l-4.49,7.78l-1.01-0.58l3.36-5.82c-0.73,0.18-1.18-0.08-1.88-0.48l0.42-0.73c1.36,0.79,2.12,0.34,2.82-0.61L93.56,31.69z"/>
                    <path d="M54.66,103l-0.46,0.79c-2.63,0.47-5.79,2.63-7.23,5.15l-1.07-0.62c1.5-2.6,5-4.6,7.1-5.04l0.01-0.02l-4.27-2.46l0.52-0.9L54.66,103z"/>
                </g>
                <g>
                    <g ref="seconds">
                        <rect x="69.5" y="9" width="1" height="68"/>
                        <path d="M70,81.5c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5S71.38,81.5,70,81.5z M70,77.5c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5S70.83,77.5,70,77.5z"/>
                    </g>
                    <g ref="minutes">
                        <polygon points="70.5,22.02 71,70 69,70 69.5,22.02"/>
                    </g>
                    <g ref="hours">
                        <polygon points="69,70 69.5,43 70.5,43 71,70"/>
                    </g>
                </g>
            </svg>
        </div>
    </div>

</template>

<script>

import { mapState } from 'vuex'

import { gsap, ScrollTrigger } from 'src/gsap'

export default {
    name: 'AnalogClock',
    props: {
        time: {
            type: Object,
            required: true
        },
        invert: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        isActive: false,
    }),
    mounted() {

        this.$inner = this.$refs.inner
        this.$seconds = this.$refs.seconds
        this.$minutes = this.$refs.minutes
        this.$hours = this.$refs.hours

        window.addEventListener('focus', () => {
            if(this.isActive) {
                this.reset()
                this.start(0)
            }
        }, false);
    },
    computed: {
        ...mapState({
            isReady: state => state.global.isReady
        }),
    },
    methods: {
        init() {
            this.scrollTrigger = ScrollTrigger.create({
                trigger: this.$refs.el,
                start: 'center bottom',
                end: 'bottom top',
                once: true,
                onToggle: ({ isActive }) => {
                    if(isActive) {
                        this.start()
                    }
                }
            })
        },
        reset() {
            this.scrollTrigger.kill()

            if(this.isActive) {
                this.isActive = false

                // Kill all repeated tweens
                gsap.killTweensOf(this.$seconds)
                gsap.killTweensOf(this.$minutes)
                gsap.killTweensOf(this.$hours)

                // Clear props
                gsap.set([this.$inner, this.$seconds, this.$minutes, this.$hours], {
                    clearProps: 'all'
                })
            }
        },
        start(animationInDuration=2) {

            this.isActive = true
            this.scrollTrigger.kill()

            // const date = new Date
            const seconds = this.time.seconds + animationInDuration
            const minutes = this.time.minutes + seconds/60 + animationInDuration/60
            const hours = this.time.hours + minutes/60 + animationInDuration/(60*60)

            const animationIn = gsap.timeline({
                defaults: {
                    duration: animationInDuration,
                    ease: 'circ.inOut'
                },
                onComplete: () => {
                    gsap.to(this.$seconds, 60, {
                        rotation: '+=360',
                        ease: 'steps(60)',
                        repeat: -1,
                    })

                    gsap.to(this.$minutes, 60*60, {
                        rotation: '+=360',
                        ease: 'none',
                        repeat: -1,
                    })

                    gsap.to(this.$hours, 60*60*12, {
                        rotation: '+=360',
                        ease: 'none',
                        repeat: -1,
                    })
                }
            })

            animationIn
                .to(this.$inner, {
                    scale: 1,
                    opacity: 1,
                    ease: 'expo.inOut'
                }, 0)
                .to(this.$seconds, {
                    rotation: seconds * 360 / 60,
                    transformOrigin: '50% 84%',
                }, 0)
                .to(this.$minutes, {
                    rotation: minutes * 360 / 60,
                    transformOrigin: '50% 100%',
                }, 0)
                .to(this.$hours, {
                    rotation: hours * 360 / 12,
                    transformOrigin: '50% 100%',
                }, 0)

        }
    },
    watch: {
        isReady(ready) {
            if(ready) {
                this.init()
            } else {
                this.reset()
            }
        }
    }
};

</script>

<style lang="scss">

.o-analog-clock {}

.o-analog-clock_inner {
    width: 100%;
    height: 0;
    padding-top: 100%;
    opacity: 0;
    transform: scale(.97);
    will-change: opacity, transform;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: var(--root-color);
        transition: fill $speed $easing;
    }

    .o-analog-clock.-invert & {

        svg {
            fill: var(--root-bg);
        }
    }
}

.o-analog-clock_bg {
    fill: var(--root-bg);
    transition: inherit;

    .o-analog-clock.-invert & {
        fill: var(--root-color);
    }
}

.o-analog-clock_outline {

    .o-analog-clock.-invert & {
        fill: none;
    }
}

</style>
