<template>
    <div
        v-if="jobs && jobs.length > 0"
        class="c-jobs"
    >
        <anim-text
            tag="h3"
            text="Open positions"
            class="c-jobs_title | t-h1"
        />
        <ul class="c-jobs_list">
            <li
                v-for="(job, i) in jobs"
                :key="`member-${i}`"
                class="c-jobs_item"
            >
                <anim-text
                    tag="a"
                    type="words"
                    :href="job.link"
                    target="_blank"
                    rel="noopener"
                    class="c-jobs_link | t-link t-h2 -lower"
                    v-cursor.click
                >
                    <span>{{ job.title }}</span>
                    <icon icon="arrow-out" />
                </anim-text>
            </li>
        </ul>
    </div>
</template>

<script>

import AnimText from 'objects/AnimText';
import Icon from 'objects/Icon';

export default {
    name: 'JobsList',
    props: {
        jobs: {
            type: Array,
            default: () => []
        },
    },
    components: {
        AnimText,
        Icon,
    },
};

</script>

<style lang="scss">

.c-jobs {
    margin-top: spacer(6);

    @media #{md("sm")} {
        display: grid;
        grid-gap: var(--grid-gutter);
        grid-template-columns: grid-space(6/12) grid-space(5/12);
    }
}

.c-jobs_title {
    color: $color-gray;

    @media #{md("sm", "max")} {
        margin-bottom: spacer(4);
    }
}

.c-jobs_list {
    @include reset-list;
}

.c-jobs_item {
}

.c-jobs_link.t-link {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}


</style>
