<template>
    <component
        :is="tag"
        :href="tag == 'a' && href !== null ? href : undefined"
        :to="tag == 'router-link' && href !== null ? href : undefined"
        :title="href !== null ? title ? title : label : undefined"
        :target="tag == 'a' ? '_blank' : undefined"
        :rel="tag == 'a' ? 'noopener' : undefined"
        class="c-btn"
        :class="[{
            '-dark' : dark,
            '-small' : small,
        }]"
    >
        <span class="c-btn_bg"></span>
        <span class="c-btn_inner">
            <icon
                v-if="iconBefore !== null"
                class="c-btn_icon"
                :icon="iconBefore"
            />
            <span class="c-btn_label">
                <slot>{{ label }}</slot>
            </span>
            <icon
                v-if="iconAfter !== null"
                class="c-btn_icon"
                :icon="iconAfter"
            />
        </span>
    </component>
</template>

<script>

import Icon from 'objects/Icon';

export default {
    name: 'Btn',
    components: {
        Icon,
    },
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'Button Label',
        },
        iconBefore: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },

        // Modifiers
        dark: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">

.c-btn {
    --btn-bg-color      : transparent;
    --btn-border-color  : currentColor;
    --btn-text-color    : currentColor;

    display: inline-flex;
    padding: .3em .7em;
    color: var(--btn-text-color);
    background-color: var(--btn-bg-color);
    border-radius: 3em;
    user-select: none;

    // Pointer cursor
    @at-root a#{&}      { cursor: pointer }
    @at-root button#{&} { cursor: pointer }

    // Modifiers
    &.-dark {
        --btn-bg-color      : #{$color-dark};
        --btn-border-color  : #{$color-dark};
        --btn-text-color    : #{$color-light};
    }

    &.-light {
        --btn-bg-color      : transparent;
        --btn-border-color  : #{$color-dark};
        --btn-text-color    : #{$color-dark};
    }

    &.-small {
        padding: .2em .4em;
    }

    &.is-active {
        --btn-bg-color      : #{$color-dark};
        --btn-border-color  : var(--btn-bg-color);
        --btn-text-color    : #{$color-light};
    }
}

.c-btn_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--btn-bg-color);
    border: 2px solid var(--btn-border-color);
    border-radius: inherit;
}

.c-btn_inner {
    display: flex;
    align-items: center;
}

.c-btn_label {
    line-height: 1;
    font-size: 20px;
    text-transform: uppercase;

    & + .c-btn_icon {
        margin-left: 1em;
    }

    .c-btn.-small & {
        font-size: 1em;
    }

    @media #{md("lg")} {
        font-size: 32px;
    }
}

.c-btn_icon {
    flex-shrink: 0;

    & + .c-btn_label {
        margin-left: 1em;
    }
}

</style>
