<template>
    <app-section>
        <div class="p-projects-grid">
            <categories
                :categories="projectTypes"
                :button="true"
                class="p-projects-grid_filters"
                :class="{ 'is-disabled' : isFiltering }"
                @click="updateFilter"
            />
            <ul class="p-projects-grid_list">
                <li
                    v-for="(project, i) in projects"
                    :key="`project-${project.id}`"
                    :data-index="project.id"
                    class="p-projects-grid_item"
                    :class="{ 'is-visible' : projectsVisibility[i] }"
                >
                    <card
                        :project="project"
                        :asset-ratio="16/9"
                        asset-handle="assetHalf"
                    />
                </li>
            </ul>
        </div>
    </app-section>
</template>

<script>

import Card from 'components/Card';
import Categories from 'objects/Categories';
import AppSection from 'layout/AppSection';

import { mapState } from 'vuex'

import { gsap } from 'src/gsap'

export default {
    name: 'Projects',
    components: {
        Card,
        Categories,
        AppSection
    },
    props: {
        page: Object,
    },
    data: () => ({
        isFiltering: false,
        projectsVisibility: [],
    }),
    beforeMount() {
        for(let i = 0; i < this.projects.length; ++i) {
            this.projectsVisibility[i] = true
        }
    },
    computed: {
        ...mapState({
            projects: state => state.project.elements,
            isReady: state => state.global.isReady
        }),
        projectTypes() {

            // Create one dimension array of all projects categories
            let categories = this.projects.map(p => p.categories).flat()

            // Remove duplicate
            const projectTypes = categories.reduce((acc, current) => {
                return acc.find(item => item.id === current.id) ? acc : acc.concat([current])
            }, []);

            return projectTypes
        }
    },
    methods: {
        updateFilter(id) {
            this.isFiltering = true

            this.animationOut().then(() => {

                this.projects.forEach((project, i) => {
                    this.projectsVisibility[i] = id === null ? true : project.categories.map(c => c.id).includes(id)
                })

                if(this.projectsVisibility.some(v => v === true)) {
                    this.$nextTick(() => {
                        this.animationIn().then(() => {
                            this.isFiltering = false
                        })
                    })
                }
            })
        },

        animationOut() {

            return new Promise(resolve => {
                gsap.to('.p-projects-grid_item.is-visible', {
                    yPercent: 15,
                    opacity: 0,
                    stagger: {
                        each: .05,
                        from: 'end'
                    },
                    duration: .4,
                    ease: 'power2.in',
                    onComplete: function() {
                        gsap.set(this._targets, {
                            position: 'absolute',
                            pointerEvents: 'none',
                        })

                        resolve()
                    }
                })
            })
        },

        animationIn(delta=1) {

            return new Promise(resolve => {
                gsap.fromTo('.p-projects-grid_item.is-visible',
                    {
                        clearProps: 'position,pointerEvents',
                        yPercent: delta * 20,
                        opacity: 0,
                    },
                    {
                        yPercent: 0,
                        opacity: 1,
                        stagger: .15,
                        duration: .8,
                        ease: 'power2.out',
                        onComplete: resolve,
                    }
                )
            })
        }
    },
    watch: {
        isReady(ready) {
            if(ready) {
                this.animationIn(-1)
            }
        }
    }
};

</script>

<style lang="scss">

.p-projects-grid {
    padding-top: spacer(2);
}

.p-projects-grid_filters {

    @media #{md("xs")} {
        width: grid-space(2/3);
    }

    @media #{md("lg")} {
        width: grid-space(1/2);
    }

    &.is-disabled {
        pointer-events: none;
    }
}

.p-projects-grid_list {
    @include reset-list;

    display: flex;
    flex-wrap: wrap;
    gap: spacer(4) var(--grid-padding);
    margin-top: spacer(3);
    counter-reset: card-counter;
}

.p-projects-grid_item {
    counter-increment: card-counter;
    will-change: transform;

    @media #{md("sm", "max")} {
        width: 100%;
    }

    @media #{md("sm")} {
        width: grid-space(1/2);
    }
}


</style>
