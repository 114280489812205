<template>

    <home-intro />

    <app-section v-if="projects.length > 0">
        <ul class="p-home-grid">
            <li
                v-for="(project, i) in projects"
                :key="`home-project-${i}`"
                class="p-home-grid_item"
                :class="{ '-with-definition' : i === 1 && page.definition1 || i === 5 && page.definition2 }"
            >
                <card
                    :project="project"
                    :asset-ratio="i > 0 ? assetRatio[(i - 1) % 6] : undefined"
                    :asset-handle="i > 0 ? 'assetHalf' : 'assetFull'"
                    :show-headline="true"
                    :small-categories="i > 0"
                />

                <anim-text
                    v-if="i === 1"
                    type="block"
                    :text="page.definition1"
                    :cms="true"
                    class="p-home_definition | -serif -small"
                >
                    <template #after>
                        <icon icon="shape-circle-lines" />
                    </template>
                </anim-text>

                <anim-text
                    v-else-if="i === 5"
                    type="block"
                    :text="page.definition2"
                    :cms="true"
                    class="p-home_definition | -serif -small"
                >
                    <template #after>
                        <icon icon="shape-circle-lines" />
                    </template>
                </anim-text>
            </li>
        </ul>

    </app-section>
</template>

<script>

import AppSection from 'layout/AppSection';
import AnimText from 'objects/AnimText';
import Card from 'components/Card';
import HomeIntro from 'components/HomeIntro';
import Icon from 'objects/Icon';

export default {
    name: 'Home',
    components: {
        AppSection,
        AnimText,
        Card,
        HomeIntro,
        Icon,
    },
    props: {
        page: Object,
    },
    data: () => ({
        assetRatio: [
            16/9,
            4/3,
            4/3,
            4/3,
            1,
            4/3,
        ]
    }),
    computed: {
        projects() {
            const projects = []
            for (let id of this.page.selectedProjectsIDS) {
                projects.push(this.$store.getters['project/getElementById'](id))
            }

            return projects
        }
    }
};

</script>

<style lang="scss">

.p-home-grid {
    @include reset-list;
    display: flex;
    flex-wrap: wrap;
    gap: spacer(6) var(--grid-padding);
    counter-reset: card-counter;
}

.p-home-grid_item {
    counter-increment: card-counter;

    &:first-child {
        width: 100% !important;
        margin-left: 0 !important;
    }

    @media #{md("sm", "max")} {

        &:not(:first-child) {
            margin-top: spacer(4);
        }

        &.-with-definition {
            width: 100%;

            .c-card {
                width: grid-space(2/3);
            }

            .p-home_definition {
                width: grid-space(1/3);
                margin-top: spacer(4);
                margin-left: grid-space(2/3, 1);
            }

            &:nth-child(6) {

                .c-card {
                    margin-left: auto;
                }

                .p-home_definition {
                    margin-left: 0;
                }
            }
        }

        .c-card_cover .o-asset {
            --asset-ratio: 3/4 !important;
        }
    }

    @media #{md("xs", "max")} {
        width: 100%;

        &.-with-definition {

            .c-card {
                width: 100%
            }

            .p-home_definition {
                width: grid-space(1/2);
                margin-left: auto !important;
                margin-right: auto;
            }
        }
    }

    @media #{md("xs")} {
        width: grid-space(2/3);

        &:nth-child(3n) {
            margin-left: auto;
        }

        &:nth-child(3n+1) {
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media #{md("sm")} {

        &.-with-definition {
            display: grid;
            align-items: center;

            &:nth-child(2) {
                width: grid-space(9/12);
                grid-column-gap: grid-space(1/9, 2);
                grid-template-columns: grid-space(6/9) grid-space(3/9);
            }

            &:nth-child(6) {
                width: grid-space(7/12);
                grid-column-gap: var(--grid-gutter);
                grid-template-columns: grid-space(4/7) grid-space(3/7);
            }
        }

        &:nth-child(6n+2) {
            width: grid-space(6/12);
        }

        &:nth-child(6n+3){
            margin-top: grid-space(1/5);
            margin-left: auto;
            width: grid-space(5/12)
        }

        &:nth-child(6n+4) {
            width: grid-space(5/12);
            // margin-top: grid-space(-1/8);
            margin-right: 0;
        }

        &:nth-child(6n+5) {
            width: grid-space(8/12);
            margin-top: grid-space(1/12);
            margin-left: grid-space(2/12, 1);
        }

        &:nth-child(6n) {
            width: grid-space(3/12);
            // margin-top: grid-space(1/8);
            margin-left: 0;
            // margin-left: grid-space(1/12, 1);
        }

        &:nth-child(6n+1) {
            width: grid-space(6/12);
            margin-right: 0;
        }
    }

    @media #{md("lg")} {

        &.-with-definition {

            &:nth-child(2) {
                grid-template-columns: grid-space(6/9) grid-space(2/9);
            }

            &:nth-child(6) {
                width: grid-space(6/12);
                grid-template-columns: grid-space(4/6) grid-space(2/6);
            }
        }
    }
}

.p-home_definition {

    .svg-shape-circle-lines {
        --icon-width: 2.5em;
    }
}


</style>
