
<template>
    <header class="l-header">
        <router-link
            id="header-logo"
            ref="logo"
            to="/"
            class="l-header_logo"
            v-cursor.click
        >
            <icon
                icon="logo"
                title="Study Hall Creative"
            />
        </router-link>

        <nav
            class="l-nav"
            :class="{ 'is-hidden' : isHidden }"
            @mouseenter="isHidden = false"
        >

            <router-link
                v-for="item in nav"
                :key="`nav-item-${ item.title }`"
                :to="item.path"
                class="l-nav_link | t-link t-h4"
                v-cursor.click
            >
                <span class="l-nav_label">
                    {{ item.title}}
                </span>
            </router-link>
        </nav>
    </header>
</template>

<script>

import Icon from 'objects/Icon';

import { mapState, mapGetters } from 'vuex'
import { gsap } from 'src/gsap';

export default {
    name: 'AppHeader',
    components: {
        Icon,
    },
    props: {
        scrollVelocity: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        isHidden: true,
        scrollY: 0,
        scrollActive: false,
        lastVal: 0,
    }),
    computed: {
        ...mapState({
            nav: state => state.global.nav,
            isReady: state => state.global.isReady
        }),
        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),
    },
    mounted() {

        let scrollY
        window.addEventListener('scroll', () => {
            if(!this.scrollActive) {
                return
            }

            scrollY = window.scrollY

            if(scrollY > this.scrollY && !this.isHidden) {
                this.isHidden = true
            } else if(scrollY < this.scrollY && this.isHidden) {
                this.isHidden = false
            }

            this.scrollY = scrollY
        })
    },
    watch: {

        isLoading(loading) {

            if(!loading) {
                this.scrollActive = false
                gsap.set('#header-logo', {
                    rotation: 0,
                    overwrite: true
                })

                this.lastVal = 0
            }
        },

        isReady(ready) {

            this.isHidden = !ready

            if(ready) {
                this.scrollActive = true
                this.scrollY = window.scrollY
            }
        },

        scrollVelocity(velocity) {

            if(this.scrollActive) {
                velocity *= 1.5
                gsap.to('#header-logo', {
                    rotation: `+=${velocity}`,
                    duration: 1,
                    ease: 'none',
                    overwrite: 'auto',
                })
            }
        }
    }
};

</script>

<style lang="scss">

.l-header {
}

.l-nav,
.l-header_logo {
    z-index: 100;
    position: fixed;
}

.l-header_logo {
    top: var(--grid-padding);
    left: var(--grid-padding);
    display: inline-block;
    will-change: transform;

    .p-project & {
        --logo-bg: transparent;
        --logo-smiley: transparent;
        --logo-letters: currentColor;
    }

    opacity: 0;
    transition: opacity 0s .4s;

    html.is-ready & {
        opacity: 1;
        transition: none;
    }
}

.l-nav {
    top: var(--grid-padding);
    right: var(--grid-padding);

    display: flex;
    align-items: center;
    opacity: 0;
    transform: translate(0, -200%);
    transition: opacity $speed-slow $easing, transform $speed-slow $easing;

    html.is-loading & {
        transform: translate(0, 200%);
    }

    html.is-ready & {
        opacity: 1;
        transform: translate(0);
    }
}

.l-nav_link {
    display: inline-block;
    margin-left: var(--grid-padding);
    text-transform: uppercase;
    overflow: hidden;
    transition: transform $speed $in-easing;

    .l-nav.is-hidden & {
        pointer-events: none;
        transform: translate(0, 50%);
    }

    @for $i from 1 through 4 {

        &:nth-child(#{$i}) {
            $delay: #{($i - 1)/4}s;

            .l-nav:not(.is-hidden) & {
                transition: transform $speed $out-easing $delay;
            }
        }
    }
}

.l-nav_label {
    display: block;
    transition: inherit;

    .l-nav.is-hidden & {
        transform: translate(0, -100%);
    }
}


</style>
