<template>
    <article
        class="c-card"
        :class="{ '-counter' : counter }"
    >
        <div class="c-card_cover">
            <asset
                :asset="project[assetHandle]"
                :vimeoID="project.vimeoID"
                :ratio="assetRatio"
                :url="path"
            />
            <categories
                :categories="project.categories"
                :dark="true"
                :small="smallCategories"
                class="c-card_categories"
            />
        </div>
        <div class="c-card_content">
            <h2 class="c-card_title | t-h3">
                <router-link
                    :to="path"
                    class="t-link"
                    v-cursor.click
                >
                    {{ project.title }}
                </router-link>
            </h2>
            <p
                v-if="showHeadline && project.headline"
                class="c-card_headline | t-h3 -lower"
            >
                {{ project.headline }}
            </p>
        </div>
    </article>
</template>

<script>

import Asset from 'objects/Asset';
import Categories from 'objects/Categories';

import { linkResolver } from 'src/router';
import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Card',
    props: {
        project: {
            type: Object,
            required: true
        },
        counter: {
            type: Boolean,
            default: true
        },
        assetRatio: {
            type: Number,
            default: null
        },
        showHeadline: {
            type: Boolean,
            default: false
        },
        assetHandle: {
            type: String,
            default: 'asset'
        },
        smallCategories: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        Asset,
        Categories,
    },
    computed: {
        path() {
            return linkResolver(SECTION_HANDLES.PROJECT, this.project.slug)
        }
    }
};

</script>

<style lang="scss">

.c-card {
}

.c-card_cover {
    display: block;
    margin-bottom: spacer(1);
}

.c-card_categories {
    position: absolute;
    right: var(--unit);
    bottom: var(--unit);
}

.c-card_content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: var(--grid-gutter);
}

.c-card_title {
    flex-shrink: 0;
    width: 100%;

    .c-card.-counter &:before {
        content: counters(card-counter, "", decimal-leading-zero);
        display: inline-block;
        vertical-align: top;
        padding-right: .5em;
        font-size: .5em;
        font-weight: 400;
    }
}

.c-card_headline {
    width: calc(50% + var(--grid-gutter-half));
    text-align: right;
}

.c-card_link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    opacity: 0;
    cursor: pointer;
}

</style>
