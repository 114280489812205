/*
 * Craft GraphQL Queries
 */

import snippets from './snippets'
import { getAsset } from './assets'

// Entry status enable + disabled if logged
let status = ['enabled']
if(window.__initialData__.isLogged) {
    status.push('disabled')
}
status = JSON.stringify(status)


/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Main query
export const general = `{
    nav: globalSet(handle: "nav") {
        ... on nav_GlobalSet {
            navNodes {
                ...on navNodes_BlockType {
                    entry {
                        ${snippets.entry}
                    }
                    label
                }
            }
        }
    }
    footer: globalSet(handle: "footer") {
        ... on footer_GlobalSet {
            headline
            ${snippets.address}
            contact {
                ...on contact_BlockType {
                    label
                    hyperlink
                }
            }
            hours {
                ...on hours_BlockType {
                    startHour: timeStart @formatDateTime(format: "G")
                    startLabel: timeStart @formatDateTime(format: "ga")
                    endHour: timeEnd @formatDateTime(format: "G")
                    endLabel: timeEnd @formatDateTime(format: "ga")
                    timeZone: timeStart @formatDateTime(format: "e")
                }
            }
        }
    }
    projectTypes: categories(group: "projectTypes") {
        title
        slug
        id
    }
}`




/**********************************
 *
 *
 *  CONTENT/PAGE
 *
 *
 **********************************/

// Homepage
export const home = `{
    entry(section: "home") {
        ${snippets.entry}

        ...on home_home_Entry {
            ${snippets.seo}
            definition1
            definition2
            selectedProjects {
                id
            }
        }
    }
}`

// About
export const projects = `{
    entry(section: "projects") {
        ${snippets.entry}

        ...on projects_projects_Entry {
            ${snippets.seo}
        }
    }
}`

// About
export const about = `{
    entry(section: "about") {
        ${snippets.entry}

        ...on about_about_Entry {
            ${snippets.seo}

            # Hero
            body
            list {
                label
                body
            }

            # Services
            services {
                ...on services_BlockType {
                    headline
                    services {
                        ...on services_category_BlockType {
                            headline
                            list {
                                item: col1
                            }
                        }
                    }
                }
            }

            # Team
            teamTitle: headline
            teamMembers: selectedTeamMembers {
                ...on team_default_Entry {
                    name: title
                    job: headline
                    desc: body
                    ${getAsset('sequence')}
                }
            }

            # Jobs
            jobs {
                title
                link
            }
        }
    }
}`


/**********************************
 *
 *
 *  PROJECT
 *
 *
 **********************************/

export const project = slug => `{
    entry(section: "project", slug: "${slug}", status: ${status}) {
        ${snippets.entry}
        slug

        ...on project_default_Entry {
            ${snippets.seo}

            # Content
            headline
            asset: ${getAsset('asset')}
            assetFull: ${getAsset('asset', 'fullscreen')}
            assetHalf: ${getAsset('asset', 'halfscreen')}
            vimeoID
            color
            lightColor

            # Categories
            categories: selectedProjectTypes {
                id
                title
            }

            # Layout
            borderRadius
            builder {

                # Title
                ...on builder_blockTitle_BlockType {
                    typeHandle
                    title: heading
                }

                # Content
                ...on builder_blockContent_BlockType {
                    typeHandle
                    headline
                    children {
                        ...on builder_blockContentCol_BlockType {
                            typeHandle
                            body
                            offset
                            width
                        }
                    }
                }

                # Grid
                ...on builder_blockGrid_BlockType {
                    typeHandle
                    children {
                        ...on builder_blockAsset_BlockType {
                            typeHandle
                            asset: ${getAsset('asset')}
                            assetFull: ${getAsset('asset', 'fullscreen')}
                            assetHalf: ${getAsset('asset', 'halfscreen')}
                            display
                            marginBottom
                        }
                        ...on builder_blockVimeo_BlockType {
                            typeHandle
                            vimeoID
                            display
                            videoControls
                            videoLoop
                            marginBottom
                        }
                    }
                }

                # Bento
                ...on builder_blockBento_BlockType {
                    typeHandle
                    children {
                        ...on builder_blockBentoAsset_BlockType {
                            typeHandle
                            asset: ${getAsset('asset')}
                            assetHalf: ${getAsset('asset', 'halfscreen')}
                        }
                        ...on builder_blockBentoVimeo_BlockType {
                            typeHandle
                            vimeoID
                        }
                    }
                }

                # Theater
                ...on builder_blockTheater_BlockType {
                    typeHandle
                    headline
                    body
                    color
                    lightColor
                    children {
                        ...on builder_blockAsset_BlockType {
                            typeHandle
                            asset: ${getAsset('asset')}
                            assetFull: ${getAsset('asset', 'fullscreen')}
                            display
                        }
                        ...on builder_blockVimeo_BlockType {
                            typeHandle
                            vimeoID
                            display
                            videoControls
                            videoLoop
                        }
                    }
                }
            }
        }
    }
}`

export const projectPreloadAll = `{
    entries(section: "project", status: ${status}) {
        ${snippets.entry}
        slug
        ...on project_default_Entry {

            # Content
            headline
            asset: ${getAsset('asset')}
            assetFull: ${getAsset('asset', 'fullscreen')}
            assetHalf: ${getAsset('asset', 'halfscreen')}
            vimeoID

            # Categories
            categories: selectedProjectTypes {
                id
                title
            }
        }
    }
}`


//
export default {
    general,
    home,
    projects,
    about,

    projectPreloadAll,
    project,
}
