import store from 'src/store'
import { ScrollTrigger } from 'src/gsap'

const Color = {
    created($el, binding) {
        const value = binding.value
        const valueIsBg = typeof value === 'string'

        $el.data = {
            light: valueIsBg ? !!binding.modifiers.light : value.light,
            bg: valueIsBg ? value : value.bg
        }
    },
    mounted($el) {
        $el.data.st = ScrollTrigger.create({
            trigger: $el,
            start: 'top center',
            end: 'bottom center',
            onToggle: ({ isActive }) => {
                if (isActive) {
                    store.dispatch('global/addTheme', $el.data, {root: true})
                } else {
                    store.dispatch('global/removeTheme', $el.data, {root: true})
                }
            }
        })
    },
    beforeUnmount($el) {
        $el.data.st.kill()

        store.dispatch('global/removeTheme', $el.data, {root: true})
    },
}

export default Color
