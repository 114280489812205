/*
** Data parser
*/

import { SECTION_HANDLES, GQL_QUERIES } from 'src/constants'
import { linkResolver } from 'src/router';


const arrayHasItem = array => typeof array !== 'undefined' && array.length > 0
const firstArrayItem = array => arrayHasItem(array) ? array[0] : null
const nl2br = string => string.replace(/(?:\r\n|\r|\n)/g, '<br>')

const forceOriginalAsset = asset => {
    if (Array.isArray(asset) && asset.length > 0) {
        asset = asset[0]
    }

    return asset?.forceOriginal === true
}

const parseData = (type, data) => {

    if(type === SECTION_HANDLES.HOME) {

        // Selected projects
        const ids = []
        data.selectedProjects.forEach(p => ids.push(p.id))
        data.selectedProjectsIDS = ids

    // } else if(type === SECTION_HANDLES.PROJECTS) {

    } else if(type === SECTION_HANDLES.PROJECT) {
        data.vimeoID = arrayHasItem(data.asset) ? null : data.vimeoID

        if(forceOriginalAsset(data.asset)) {
            data.assetFull = data.asset
            data.assetHalf = data.asset
        }

        data.builder?.forEach(block => {
            block.children = block.children?.map(c => {

                if (forceOriginalAsset(c.asset)) {
                    c.assetFull = c.asset
                    c.assetHalf = c.asset
                }

                return c
            })
        })

    } else if(type === SECTION_HANDLES.ABOUT) {
        data.services = firstArrayItem(data.services)

    } else if(type === GQL_QUERIES.GENERAL) {

        // Nav
        const nav = []
        let entry
        data.nav.navNodes.forEach(item => {
            entry = firstArrayItem(item.entry);
            nav.push({
                title: item.label ? item.label : entry.title,
                path: linkResolver(entry.sectionHandle)
            })
        });

        data.nav = nav

        // Footer
        const footer = data.footer

        // Address
        footer.address = firstArrayItem(footer.address)
        if(footer.address) {
            footer.address.label = nl2br(footer.address.label)
        }

        // Hours
        let hours = firstArrayItem(footer.hours)
        if(hours !== null && hours.startLabel !== null && hours.endLabel !== null) {
            hours.startHour = Number(hours.startHour)
            hours.endHour = Number(hours.endHour)
        } else {
            hours = null
        }
        footer.hours = hours
    }

    return data
}

export default parseData
